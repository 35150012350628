import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import header from '../../../assets/images/opinions/header.png';
import resultImg from '../../../assets/images/opinions/result.png';
import Question from '../../../components/Question';
import Intro from '../../../components/Intro';
import ProgressBar from '../../../components/ProgressBar';
import { Dayjs } from 'dayjs';

import { useState, useEffect } from 'react';
import AnimatedPage from '../../../components/Animations/AnimatedPages';
import { useParams } from 'react-router-dom';
import { showData, storeOrUpdate } from '../../../plugins/api';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import Notification from '../../../components/Notifications/Notification';

const VotesDetails = () => {

    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState(null);
    const { id } = useParams()
    // const [answer, setAnswer] = useState([{ question_id: null, answer: null }]);

    const handleClickShowResult = () => {
        showData({ resource: `mobile/surevey-result`, param: id }).then((res) => {
            setShowResult((show) => !show);
            setResult(res.data.data);
        }).catch((errors) => {
            setNotify({
                isOpen: true,
                message: errors.response.data.msg,
                type: 'error'
            })
        });

    }



    const [items, setItems] = useState(null)

    const getItem = async () => {
        await showData({ resource: `mobile/surevey-question`, param: id }).then((res) => {
            const items = res.data.data.questions;
            setItems(items);
        });
    };

    useEffect(() => {
        getItem();
    }, []);

    const defaultValues = null;
    const [value, setValue] = useState<Dayjs | null>(null);
    const formMethods = useForm<FormData>({
        defaultValues: defaultValues,
    });
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const { handleSubmit, setError } = formMethods;
    // const { register, formState: { errors } } = useFormContext();

    const onFormSubmit = (e) => {


        e.preventDefault()

        const form: any = document.querySelector('.form')

        const formData: any = new FormData(form)
        let data = [];


        for (const value of formData.entries()) {

            let check = value[0].includes('&&choose')
            let question = value[0].split('&&choose')
            let answer = {};
            if (check) {
                answer = {
                    "question_id": question[0],
                    "answer": [value[1]]
                };
            } else {
                answer = {
                    "question_id": value[0],
                    "answer": value[1]
                };
            }


            data.push(answer)
        }




        let answer = {
            "answer": data
        }





        let actionRoute = { resource: `mobile/surevey-question-answer`, param: id, data: answer, method: 'post' };

        return storeOrUpdate(actionRoute).then(
            (response) => {
                setNotify({
                    isOpen: true,
                    message: 'تم ارسال استطلاعك بنجاح',
                    type: 'success'
                })
            }
        ).catch((errors) => {
            setNotify({
                isOpen: true,
                message: errors.response.data.msg,
                type: 'error'
            })
        })

    };



    return (
        <AnimatedPage>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <section className='vote page'>
                <Intro image={header} />
                <div className='page__content mt-3'>
                    <Container>
                        <Grid container spacing={1}>
                            <Grid className="vote__right" item xs={12} sm={6}>
                                <Typography className='font-bolder text-primary mt-2' variant='h5' component='h2'>
                                    استطلاعات رأي

                                </Typography>
                                <FormProvider {...formMethods}>
                                    <form className='form' onSubmit={onFormSubmit}>
                                        {
                                            items &&
                                            items.map((item: any) => (

                                                <div className='mt-1' key={item.id}>

                                                    <div>

                                                        {

                                                            item.mcqs.length > 1 &&
                                                            <FormControl>
                                                                <FormLabel id="demo-radio-buttons-group-label">
                                                                    <Typography className='font-bolder' variant='h6' component='h3'>
                                                                        {item.question}
                                                                    </Typography>
                                                                </FormLabel>
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    defaultValue="female"
                                                                    name="radio-buttons-group"
                                                                >
                                    

                                                                    {item.mcqs && item.mcqs.map((mcq: any) => (
                                                                        <div>

                                                                            <FormControlLabel name={`${item.id}&&choose`} key={mcq.id} value={`${mcq.id}`} control={<Radio />} label={mcq.mcq} />
                                                                        </div>
                                                                    ))}
                                                                </RadioGroup>
                                                            </FormControl>
                                                        }

                                                        {item.mcqs.length == 0
                                                            &&
                                                            <div>

                                                                <Typography className='font-bolder' variant='h6' component='h3'>
                                                                    {item.question}
                                                                </Typography>
                                                               
                                                                <FormControl fullWidth variant="outlined" >
                                                                    <TextField

                                                                        name={item.id}
                                                                        fullWidth

                                                                    />

                                                                </FormControl>

                                                            </div>
                                                        }


                                                    </div>

                                                </div>
                                            ))}
                                        <Grid>
                                            <Button type='submit' variant='contained'>
                                                تصويت
                                            </Button>
                                        </Grid>

                                    </form>
                                </FormProvider>

                            </Grid>
                            <Grid item xs={12} sm={6} container justifyContent='end' alignItems='center' className="vote__left" >
                                <Grid item md={6} xs={12}>

                                    {
                                        !showResult &&
                                        <Button onClick={handleClickShowResult}>
                                            <img src={resultImg} alt="result" />
                                            <Typography variant='h6' component='h2' className='text-primary font-bolder'>
                                                نتيجة الاستطلاع
                                            </Typography>
                                        </Button>
                                    }

                                    {
                                        showResult &&

                                        result && result.map((res: any) => (

                                            res.mcqs &&
                                            res.mcqs.map((mcq: any) => (
                                                <div key={mcq.id}>
                                                    <ProgressBar text={mcq.mcq} percent={mcq.percent} />
                                                </div>
                                            ))

                                        ))


                                    }

                                </Grid>

                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </section>
        </AnimatedPage >
    )
}

export default VotesDetails