import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const Blog = ({ title, image, description, hint,page, id }) => {
    return (
        <div className="gallery__wrap">
            <Link to={`/${page}/${id}`}>
                <div>
                    <img src={image} alt="tree image1" />
                </div>
                {

                    hint&&
                    <Grid item xs container direction="row-reverse" spacing={2}>
                        <Typography className='hint'>
                          تم النشر في   :  {hint} 
                        </Typography>
                    </Grid>
                }
                <h3>
                    {title}
                </h3>
                {
                    description &&

                    <p>
                        {description}
                    </p>
                }
            </Link>
        </div>

    )
}

export default Blog