import { Autocomplete, Card, CardActionArea, CardContent, FormControl, FormLabel, Grid, Paper, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useEffect, useState } from 'react'
import group from '../../assets/images/profile/group.svg';
import avatar from '../../assets/images/public/avatar.svg'
import email from '../../assets/images/public/email.svg'
import phone from '../../assets/images/public/phone.svg'
import header from '../../assets/images/opinions/header.png';
import Intro from '../../components/Intro';
import dummyData from '../../plugins/data/data';
import DialogAdd from '../../components/Dialog/Add';
import AddSons from '../../components/FormsWebsite/Profile/AddSons';
import AddWife from '../../components/FormsWebsite/Profile/AddWife';
import { useNavigate } from "react-router-dom";
import AddFather from '../../components/FormsWebsite/Profile/AddFather';
import { indexData, storeOrUpdate } from '../../plugins/api';
import DialogFire from '../../components/Dialog/Fire';
import Button from '../../components/Controls/Button';
import TextInputX from '../../components/Forms/TextInputX';
import { FormProvider, useForm } from 'react-hook-form';
import Notification from '../../components/Notifications/Notification';
import AnimatedPage from '../../components/Animations/AnimatedPages';
import ProfileShow from '../../components/FormsWebsite/Profile/ProfileShow';
const Profile = () => {
    const navigate = useNavigate();
    const getUser = JSON.parse(window.localStorage.getItem("user"));
    const [render, setRender] = useState(true);
    const [user, setUser] = useState(getUser);
    const [parents, setParents] = useState([]);
    const [parent, setParent] = useState(null);
    const [mothers, setMothers] = useState([]);
    const [mother, setMother] = useState(null);
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const formMethods = useForm<FormData>({
        defaultValues: null,
    });
    const { handleSubmit, setError } = formMethods;

    const getParents = () => {
        indexData({ resource: `general/findparent?search_key=&gender=boy` }).then((res) => {
            const parents = res.data.data
            setParents(parents);

        });
    };


    const getMothers = () => {
        indexData({ resource: `general/findparent?search_key=&gender=girl` }).then((res) => {
            const mothers = res.data.data
            setMothers(mothers);

        });
    };



    const onFormSubmit = async (data: any) => {

        let fd = new FormData();
        if (parent)
            fd.append("parent_id", parent)
        if (mother)
            fd.append("mother_id", mother)

        fd.append("_method", "PUT")

        let actionRoute = { resource: `auth/editfamilymember/`, param: null, data: fd, method: 'post' };

        return storeOrUpdate(actionRoute).then(
            (response) => {
                getProfile();
                setNotify({
                    isOpen: true,
                    message: 'تم تحديث بياناتك بنجاح',
                    type: 'success'
                })
                // setTimeout(() => {
                //     window.location.reload()
                // }, 2500)
            }
        ).catch((errors) => {
            setNotify({
                isOpen: true,
                message: errors.response.data.msg,
                type: 'error'
            })
        })

    };


    const getProfile = async () => {
        await indexData({ resource: `profile` }).then((res) => {
            const user = res.data.data;
            setUser(user);
            window.localStorage.setItem("user", JSON.stringify(user));

        });
    };

    const notifyRender = () => {

        setNotify({
            isOpen: true,
            message: 'تم تحديث بياناتك بنجاح',
            type: 'success'
        })


        setTimeout(() => {
            window.location.reload()
        }, 2500)
    }

    useEffect(() => {
        getParents()
        getMothers()

    }, []);

    const fetchData = () => {
        setConfirmDialogFather({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })
        setConfirmDialogMother({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })
        setConfirmDialogSon({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })
        setConfirmDialogWife({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })
        getProfile()
        setTimeout(() => {
            notifyRender()
        }, 500);

    }


    const [confirmDialogSuccess, setConfirmDialogSuccess] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialogSuccess = () => {
        setConfirmDialogSuccess({
            isOpen: true,
            title: 'تم إرسال طلب الفعالية للإدارة بنجاح',
            subTitle: 'سوف يتم مراجعة بيانات ملاحظاتك مع الإدارة وإرسال الرد لك',
            onConfirm: () => { }
        })
    }


    const [confirmDialogFather, setConfirmDialogFather] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = (title) => {
        setConfirmDialogFather({
            isOpen: true,
            title: title,
            subTitle: '',
            onConfirm: () => { }
        })
    }

    
    const [confirmDialogProfile, setConfirmDialogProfile] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialogProfile = (title) => {
        setConfirmDialogProfile({
            isOpen: true,
            title: title,
            subTitle: '',
            onConfirm: () => { }
        })
    }



    const [confirmDialogMother, setConfirmDialogMother] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialogMother = (title) => {
        setConfirmDialogMother({
            isOpen: true,
            title: title,
            subTitle: '',
            onConfirm: () => { }
        })
    }

    const [confirmDialogWife, setConfirmDialogWife] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialogWife = (title) => {
        setConfirmDialogWife({
            isOpen: true,
            title: title,
            subTitle: '',
            onConfirm: () => { }
        })
    }

    const [confirmDialogSon, setConfirmDialogSon] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialogSon = (title) => {
        setConfirmDialogSon({
            isOpen: true,
            title: title,
            subTitle: '',
            onConfirm: () => { }
        })
    }



    return (
        <AnimatedPage>
            <section className='profile'>
                <Intro image={header} />
                <Notification
                    notify={notify}
                    setNotify={setNotify}
                />
                <div className='mt-2'>
                    {
                        render
                        &&
                        <Container>
                            <Grid container xs spacing={3}>
                                <Grid item lg={8} xs={12} >
                                    <FormProvider {...formMethods}>
                                        <Paper className='profile__right relative'>
                                            <form onSubmit={handleSubmit(onFormSubmit)}>
                                                <div className='text-center'>
                                                    <Typography marginTop={2} className='font-bolder' gutterBottom variant="h5" component="div">
                                                        بيانات العائلة
                                                    </Typography>
                                                    <div>
                                                        <img
                                                            className='avatar-family'
                                                            src={group}
                                                            alt="avatar"
                                                        />
                                                    </div>
                                                </div>
                                                <Typography className='font-bolder' gutterBottom variant="h6" component="div">
                                                    أفراد العيلة
                                                </Typography>

                                                <div className='mt-2'>
                                                    <Grid container alignItems={'center'} >
                                                        <Grid item md={10} xs={12}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <Autocomplete
                                                                    fullWidth
                                                                    disablePortal
                                                                    id="father"
                                                                    options={parents}
                                                                    defaultValue={user.member?.parent}
                                                                    getOptionLabel={(option: any) => {
                                                                        setParent(option.id);
                                                                        return option.full_name_ar

                                                                    }}

                                                                    renderInput={(params) => <TextInputX {...params} defaultValue={user.member?.parent} field={'parent_id'} label={'اسم الأب '}
                                                                        rules={{}} />}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button className='btn-add-profile' text={'اضافة أب'} variant={'text'} onClick={() => openDialog('اضافة أب')}>
                                                            </Button>
                                                            <DialogFire btnName="اضافة أب" confirmDialog={confirmDialogFather} setConfirmDialog={setConfirmDialogFather}>
                                                                <AddFather gender={'M'} url='add_parent' fetchData={fetchData}></AddFather>
                                                            </DialogFire>
                                                        </Grid>

                                                    </Grid>
                                                </div>

                                                <div className='mt-2'>
                                                    <Grid container alignItems={'center'} >
                                                        <Grid item md={10} xs={12}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <Autocomplete
                                                                    fullWidth
                                                                    disablePortal
                                                                    id="mother"
                                                                    defaultValue={user.member?.mother}
                                                                    options={mothers}
                                                                    getOptionLabel={(option: any) => {
                                                                        setMother(option.id);
                                                                        return option.full_name_ar

                                                                    }}
                                                                    renderInput={(params) => <TextInputX {...params} defaultValue={user.member?.mother} field={'mother_id'} label={'اسم الأم '}
                                                                        rules={{}} />}
                                                                />

                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item>
                                                            <Button className='btn-add-profile' text={'اضافة أم'} variant={'text'} onClick={() => openDialogMother('اضافة أم')}>
                                                            </Button>
                                                            <DialogFire btnName="اضافة أم" confirmDialog={confirmDialogMother} setConfirmDialog={setConfirmDialogMother}>
                                                                <AddFather gender={'F'} url='add_mother' fetchData={fetchData}></AddFather>
                                                            </DialogFire>
                                                        </Grid>
                                                    </Grid>
                                                </div>


                                            </form>

                                            <div className='mt-2'>
                                                <Grid container alignItems={'center'} >
                                                    <Grid item md={10} xs={12}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <Autocomplete
                                                                fullWidth
                                                                disablePortal
                                                                id="wife"
                                                                defaultValue={user.member?.wife[user.member?.wife.length - 1]}
                                                                options={user.member?.wife}
                                                                getOptionLabel={(option: any) => {
                                                               
                                                                    return option.full_name_ar

                                                                }}
                                                                renderInput={(params) => <TextInputX {...params} defaultValue={user.member?.wife[0]} field={'wife_id'} label={'الزوجات '}
                                                                    rules={{}} />}
                                                            />

                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item >
                                                        <Button className='btn-add-profile' text={'اضافة زوجة'} variant={'text'} onClick={() => openDialogWife('اضافة زوجة')}>
                                                        </Button>
                                                        <DialogFire btnName="اضافة زوجة" confirmDialog={confirmDialogWife} setConfirmDialog={setConfirmDialogWife}>
                                                            <AddWife gender={'F'} url='add_wife' fetchData={fetchData}></AddWife>
                                                        </DialogFire>
                                                    </Grid>
                                                </Grid>
                                            </div>


                                            <div className='mt-2'>
                                                <Grid container alignItems={'center'} >
                                                    <Grid item md={10} xs={12}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <Autocomplete
                                                                fullWidth
                                                                disablePortal
                                                                id="sons"
                                                                defaultValue={user.member?.sons[user.member?.sons.length - 1]}
                                                                options={user.member?.sons}
                                                                getOptionLabel={(option: any) => {
                                                                 
                                                                    return option.full_name_ar

                                                                }}
                                                                renderInput={(params) => <TextInputX {...params} defaultValue={user.member?.sons[0]} field={'son_id'} label={'الأبناء '}
                                                                    rules={{}} />}
                                                            />

                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item >
                                                        <Button className='btn-add-profile' text={'اضافة ابن / ابنة'} variant={'text'} onClick={() => openDialogSon('اضافة ابن / ابنة')}>
                                                        </Button>
                                                        <DialogFire btnName="اضافة ابن / ابنة" confirmDialog={confirmDialogSon} setConfirmDialog={setConfirmDialogSon}>
                                                            <AddSons gender={'F'} url='add_children' fetchData={fetchData}></AddSons>
                                                        </DialogFire>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className='mt-2 text-center mb-2'>
                                                <Button onClick={handleSubmit(onFormSubmit)} className='profile__save' variant='contained'>
                                                    حفظ التعديلات
                                                </Button>
                                            </div>

                                        </Paper>
                                    </FormProvider>
                                </Grid>

                                <Grid item lg={4} xs={12}>
                                    <Paper className='profile__left'>
                                        <div className='text-center'>
                                            <Typography marginTop={2} className='font-bolder' gutterBottom variant="h6" component="div">
                                                بياناتي الشخصية
                                            </Typography>
                                            <Grid xs container direction="row-reverse" spacing={2}>
                                            <Grid item>
                                                <Typography className='font-bolder view-link' gutterBottom variant="body1" component="p" >
                                                <Grid item >
                                                        <Button className='btn-add-profile' text={'الملف الشخصى كعرض'} variant={'text'} onClick={() => openDialogProfile('الملف الشخصى ')}>
                                                        </Button>
                                                        <DialogFire btnName="الملف الشخصى كعرض" confirmDialog={confirmDialogProfile} setConfirmDialog={setConfirmDialogProfile}>
                                                           <ProfileShow user={user}></ProfileShow>
                                                        </DialogFire>
                                                    </Grid>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                            <div>
                                                <div className='avatar-container'>
                                                    <div className='avatar-container__online'>
                                                    </div>
                                                    {
                                                        user.member?.image &&
                                                        <img id='img-user' width={'50px'} height={'50px'} src={user.member?.image} alt="avatar" />
                                                    }

                                                    {
                                                        user.member?.image == null &&
                                                        <img width={'50px'} height={'50px'} src={avatar} alt="avatar" />
                                                    }
                                                </div>
                                                <Typography fontWeight={'bolder'} gutterBottom variant="body1" component="p" >
                                                    {user.name}
                                                </Typography>
                                            </div>
                                        </div>
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item>
                                                <img
                                                    className='mv-top'
                                                    src={phone}
                                                    alt="phone"
                                                />
                                            </Grid>
                                            <Grid item>
                                                {user.phone}
                                            </Grid>
                                        </Grid>
                                        <div className='mt-1'>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <img
                                                        className='mv-top'
                                                        src={email}
                                                        alt="email"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    {user.email}
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className='mt-2 text-center'>
                                            <Button variant='contained' onClick={() => navigate('/profile/edit')} >
                                                تعديل البيانات
                                            </Button>
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                    }
                </div>

            </section>
        </AnimatedPage>

    )
}



export default Profile