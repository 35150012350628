
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "../components/Layouts/main";
import { AnimatePresence } from "framer-motion";
import Home from "../pages/Home";
import Family from "../pages/Family";
import Events from "../pages/Events";
import Offers from "../pages/Offers";
import Gallery from "../pages/Gallery";
import Blogs from "../pages/Blogs";
import Albums from "../pages/Gallery/Details";
import Requests from "../pages/Requests";
import Donations from "../pages/Donations";
import Auth from "../components/Layouts/Auth";
import Opinions from "../pages/Opinions";
import DonationPrevious from "../pages/Donations/Previous";
import RequestPrevious from "../pages/Requests/Previous";
import New from "../pages/Donations/New";
import Votes from "../pages/Votes";
import Result from "../pages/Opinions/Details";
import EventDetails from "../pages/Events/Details";
import BlogDetails from "../pages/Blogs/Details";
import Profile from "../pages/Profile";
import EditProfile from "../pages/Profile/Edit";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import VerifyCodeEmail from "../pages/Auth/VerifyCode/Email";
import VerifyCodePhone from "../pages/Auth/VerifyCode/Phone";
import NewPassword from "../pages/Auth/ResetPassword/NewPassword";
import GalleryDetails from "../pages/Gallery/Details";
import RequireAuth from "../plugins/features/auth/RequireAuth";
import Welcome from "../plugins/features/auth/Welcome";
import UsersList from "../plugins/features/users/UsersList";
import RequireUnAuth from "../plugins/features/auth/RequireUnAuth";
import Soon from "../pages/soon";
import Survey from "../pages/Survey";
import VotesDetails from "../pages/Votes/Details";
import OpinionsDetails from "../pages/Opinions/Details";
import OfferDetails from "../pages/Offers/Details";

import ChooseVerifyOtp from "../pages/Auth/ChooseVerify/Otp";
import ChooseVerify from "../pages/Auth/ChooseVerify";
import ResetPasswordOtp from "../pages/Auth/ResetPassword/Otp";
import ResetPasswordType from "../pages/Auth/ResetPassword/Type";
import FamilyTree from "../pages/FamilyTree";


function Routing() {
    return (
        <Router>
            <AnimatePresence mode="wait">
                <Routes>
                    {/* Main layout */}

                    <Route path="/" element={<Main />} >
                        <Route element={<RequireAuth />}>
                            <Route index element={<Home />} />
                            <Route path="family/:id" element={<Family />} />
                            <Route path="events" element={<Events />} />
                            <Route path="events/:id" element={<EventDetails />} />
                            <Route path="offers" element={<Offers />} />
                            <Route path="offers/:id" element={<OfferDetails />} />
                            <Route path="gallery" element={<Gallery />} />
                            <Route path="gallery/:id" element={<GalleryDetails />} />
                            <Route path="blogs" element={<Blogs />} />
                            <Route path="blogs/:id" element={<BlogDetails />} />
                            <Route path="albums" element={<Albums />} />
                            <Route path="requests" element={<Requests />} />
                            <Route path="requests/:id" element={<RequestPrevious />} />
                            <Route path="donations" element={<Donations />} />
                            <Route path="donations/previous" element={<DonationPrevious />} />
                            <Route path="donations/new" element={<New />} />
                            <Route path="opinions" element={<Opinions />} />
                            <Route path="survey" element={<Survey />} />
                            <Route path="survey/votes" element={<Votes />} />
                            <Route path="survey/opinions" element={<Opinions />} />
                            <Route path="survey/votes/:id" element={<VotesDetails />} />
                            <Route path="survey/opinions/:id" element={<OpinionsDetails />} />

                        </Route>
                        <Route path="profile" element={<Profile />} />
                        <Route path="profile/edit" element={<EditProfile />} />
                    </Route>
                    {/* Auth layout */}
                    <Route  element={<Auth />} >
                        <Route path="family-tree" element={<FamilyTree />} />
                    </Route>
                    <Route path="/auth/" element={<Auth />} >
                        <Route element={<RequireUnAuth />} >
                            <Route path="login" element={<Login />} />
                            <Route path="register" element={<Register />} />
                        </Route>
                        <Route path="verify" element={<ChooseVerify />} />
                        <Route path="verify/:id" element={<ChooseVerifyOtp />} />
                        <Route path="reset-password/:type" element={<ResetPasswordType />} />
                        <Route path="reset-password/:type/:value" element={<ResetPasswordOtp />} />
                        <Route path="verify-code/email" element={<VerifyCodeEmail />} />
                        <Route path="verify-code/phone" element={<VerifyCodePhone />} />
                        <Route path="new-password" element={<NewPassword />} />

                    </Route>
                </Routes>
            </AnimatePresence>
        </Router>
    );
}

export default Routing;
