import { useLocation, Navigate, Outlet } from "react-router-dom"


const RequireAuth = () => {
    const token = window.localStorage.getItem("token")
    const getUser = JSON.parse(window.localStorage.getItem("user"));
    const location = useLocation()

    return (
        token && getUser.user_verifiedFamilyDetails
            ? <Outlet />
            : <Navigate to="/auth/login" state={{ from: location }} replace />
    )
}
export default RequireAuth