import http from '../axios/index';

export const indexData = ({ resource }) => {
  return http({
    url: `${resource}`,
    method: 'get'
  });
};

export const storeData = ({ resource, data }) => {
  return http({
    url: `${resource}`,
    method: 'post',
    data,
  });
};

export const showData = ({ resource, param }) => {
  return http({
    url: `${resource}/${param}`,
    method: 'get'
  });
};

export const deleteData = ({ resource, param }) => {
  return http({
    url: `${resource}/${param}`,
    method: 'delete'
  })
}


export const storeOrUpdate= ({ resource,param,data,method }) => {
  return http({
    url: param ? `${resource}/${param}`: `${resource}`,
    data,
    method: method
  });
};