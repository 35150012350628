import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import dummyData from '../../../../plugins/data/data';
import UploadFile from '../../../Forms/UploadFile';
import { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { indexData, storeOrUpdate } from '../../../../plugins/api';

import TextInputX from '../../../Forms/TextInputX';
import Button from '../../../Controls/Button';
import Notification from '../../../Notifications/Notification';
import DateInput from '../../../Forms/DateInput';
import SelectInput from '../../../Forms/SelectInput';
import CustomizedSwitches from '../../../Forms/Switch';


const AddWife = ({ fetchData, url, gender }) => {
    const defaultValues = null;
    const [value, setValue] = useState<Dayjs | null>(null);
    const [family, setFamily] = useState(false);


    const handleFamily = (value) => {
        setFamily(value)

    }
    const formMethods = useForm<FormData>({
        defaultValues: defaultValues,
    });
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const { handleSubmit, setError } = formMethods;

    const handleDate = (value) => {
        let date = value.split('-')
        let dateFormat = date[2] + "/" + date[1] + "/" + date[0]
        return dateFormat;
    }
    const [maritialStatuses, setMaritialStatuses] = useState([]);
    const [maritialStatus, setMaritialStatus] = useState('married');
    const getMaritialStatuses = () => {
        indexData({ resource: `general/married_martial_status` }).then((res) => {
            const maritialStatuses = res.data.data
            setMaritialStatuses(maritialStatuses);
      


        });
    };

    const setValueMaritialStatus = (value) => {
        setMaritialStatus(value)

    }

    
    useEffect(() => {
        getMaritialStatuses()
    }, []);






    const onFormSubmit = async (data: any) => {

        data.birthdate_g = handleDate(data.birthdate_g)

        let fd = new FormData();
        const keys = Object.keys(data);
        console.log(data)
        keys.forEach((key) => {
            if (key != 'birthdate_g') {
                fd.append(key, data[key])
            }
            if (key == 'image') {
                fd.append(key, data[key][0])
            }


        });
        fd.append('marital_status', maritialStatus);

        if (data.birthdate_g != 'undefined/undefined/') {
            fd.append('birthdate_g', data.birthdate_g);
        } else {
            fd.append('birthdate_g', '10/10/2010');
        }

        if (family) {
            fd.append('family_name', 'azhar');
        }

        let actionRoute = { resource: `mobile/${url}`, param: null, data: fd, method: 'post' };
        if (defaultValues) {
            actionRoute = { resource: `mobile/${url}`, param: defaultValues.id, data: data, method: 'put' };
        }
        return storeOrUpdate(actionRoute).then(
            (response) => {
                fetchData()

            }
        ).catch((errors) => {
            setNotify({
                isOpen: true,
                message: errors.response.data.msg,
                type: 'error'
            })

        })

    };

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onFormSubmit)}>

                <TextInputX field={'first_name'} label={'الأسم الأول'}
                    rules={{
                        required: true
                    }} />


                <TextInputX field={'second_name'} label={'اسم الأب '}
                    rules={{
                        required: true
                    }} />



                <TextInputX field={'third_name'} label={' اسم الجد'}
                    rules={{
                        required: true,

                    }} />


                <SelectInput keyShow={'value'} options={maritialStatuses} setValue={setValueMaritialStatus} field={'marital_status'} label={' الحالة الأجتماعية'}
                    rules={{


                    }} />

                {
                    maritialStatus == 'married' &&


                    <TextInputX field={'national_id_no'} label={'رقم الهوية'}
                        defaultValue=""
                        rules={{

                        }} />
                }

                <div className='rtl-radio'>
                    <CustomizedSwitches changeAction={handleFamily} defaultChecked={false} rules={{}} field={'image_show'} label={'هل من عائلة الأزهر'}></CustomizedSwitches>
                </div>

                {
                    !family &&

                    <TextInputX field={'family_name'} label={'اسم العائلة '}
                        defaultValue=""
                        rules={{
                        }} />
                }

                {
                    maritialStatus == 'married' &&

                    <TextInputX field={'phone'} label={'رقم الجوال '}
                        defaultValue=""
                        rules={{

                        }} />
                }


                {
                    maritialStatus == 'married' &&

                    <FormControlLabel control={<Checkbox />} label="أرسل اللينك عبر  رقم الجوال" />

                }

                {
                    maritialStatus == 'married' &&

                    <TextInputX field={'email'} label={'البريد الألكترونى'}
                        defaultValue=""
                        rules={{

                        }} />
                }

                {
                    maritialStatus == 'married' &&

                    <FormControlLabel control={<Checkbox />} label="أرسل اللينك عبر البريد الألكترونى" />
                }
                {
                    maritialStatus == 'married' &&

                    <DateInput field={'birthdate_g'} label={'تاريخ الميلاد'}
                        defaultValue=""
                        rules={{

                        }} />
                }

                <div hidden>
                    <TextInputX field={'date_type'} label={'تاريخ الميلاد'}
                        defaultValue="g"
                        rules={{

                        }} />


                    <TextInputX field={'gender'} label={''}
                        defaultValue={gender}
                        rules={{

                        }} />

                </div>


                <Grid container justifyContent={'center'}>
                    <Grid item>
                        <Button type='submit' className='btn-popup' variant='contained' autoFocus >
                            اضافة
                        </Button>
                    </Grid>
                </Grid>

                <Notification
                    notify={notify}
                    setNotify={setNotify}
                />
            </form>
        </FormProvider >

    )
}

export default AddWife