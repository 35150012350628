
import Carousel from "react-elastic-carousel";
import SliderCard from "./Card";
import SliderWrapper from "./Wrapper";


const Slider = ({ items, title, SliderType, page }) => {

    const breakPoints = [
        { width: 550, itemsToShow: 1 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: items.length == 1 ? 1 : 2 },
    ];
    return (

        <SliderWrapper title={title} SliderType={SliderType} >
            <Carousel breakPoints={breakPoints}>
                {items.map((item: any) => (
                    <SliderCard page={`${page}/${item.id}`} banner={SliderType == 'events' ? item.title : ''} image={item.image} SliderType={SliderType} />
                ))}
            </Carousel>
        </SliderWrapper>

    )
}

export default Slider