import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material"
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import logo from '../../../assets/images/home/logo.svg';
import avatar from '../../../assets/images/icons/avatar.svg';
import camera from '../../../assets/images/icons/camera.svg';
import AnimatedPage from "../../../components/Animations/AnimatedPages";
import CustomizedSwitches from "../../../components/Forms/Switch";
import Form from "../../../components/Login/Form";
import Preloader from "../../../components/Preloader";
import Close from "../../../assets/images/icons/close.svg";
import Eye from "../../../assets/images/icons/eye.png";
import { useEffect, useState } from "react"
import FormRegister from "../../../components/FormsWebsite/Register";

const Register = () => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((showConfirm) => !showConfirm);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    window.localStorage.clear();

    return (
        <AnimatedPage>
            <Preloader />
            <section className='auth register'>
                <Grid container spacing={1}>

                    <Grid className="auth__right" item xs={12} sm={6}>
                        <div >
                            <Container>
                                <div>
                                    <Link to="/" >
                                        <img className='logo' src={logo} alt="logo" />
                                    </Link>
                                </div>
                                <h2>
                                    إنشاء حساب
                                </h2>
                           
                                <FormRegister />

                            </Container>
                        </div>
                    </Grid>
                    <Grid className="auth__left" item xs={12} sm={6}>
                    </Grid>
                </Grid>
            </section>
        </AnimatedPage>
    )
}

export default Register