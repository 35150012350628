import axios from 'axios';
const getUser = JSON.parse(window.localStorage.getItem("user"));
const http = axios.create({
  baseURL: 'https://dashboard.alazharfamily.com/api/',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
})

// Request interceptor
http.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('token') || getUser?.token) {

      config.headers.Authorization = `Bearer ${localStorage.getItem('token') || getUser.token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)
// 104|hZpX0KfW1yWL46VheLgLbDVWaoqRtVgdZcWak7oR

export default http;
