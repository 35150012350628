import { Autocomplete, FormControl, FormLabel, Grid, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import dummyData from '../../../../plugins/data/data';
import UploadFile from '../../../Forms/UploadFile';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { storeOrUpdate } from '../../../../plugins/api';

import TextInputX from '../../../Forms/TextInputX';
import Button from '../../../Controls/Button';
import Notification from '../../../Notifications/Notification';
import DateInput from '../../../Forms/DateInput';
import avatar from '../../../../assets/images/public/avatar.svg'
import TextInput from '../../../Forms/TextInput';


const ProfileShow = ({ user }) => {


    return (

        <div>

            <div>
                <div className='avatar-container'>
                    <div className='text-center'>

                        {
                            user.member.image &&
                            <img id='img-user' width={'50px'} height={'50px'} src={user.member.image} alt="avatar" />
                        }

                        {
                            user.member.image == null &&
                            <img width={'50px'} height={'50px'} src={avatar} alt="avatar" />
                        }
                        <Typography fontWeight={'bolder'} gutterBottom variant="body1" component="p" >
                            {user.name}
                        </Typography>
                    </div>
                </div>

            </div>
            <TextInput field={'phone'} label={'رقم الجوال'}
                disabled
                defaultValue={user.phone}
                rules={{
                    required: true
                }} />


            <TextInput field={'email'} label={' البريد الألكترونى'}
                disabled
                defaultValue={user.email}
                rules={{
                    required: true
                }} />


            <TextInput field={'job'} label={'الوظيفة'}
                disabled
                defaultValue={user.member.jobtitle}
                rules={{
                    required: true
                }} />


            <TextInput field={'work_address'} label={'عنوان العمل'}
                disabled
                defaultValue={user.member.work_address}
                rules={{
                    required: true
                }} />


            <TextInput field={'address'} label={'عنوان الأقامة'}
                disabled
                defaultValue={user.member.address}
                rules={{
                    required: true
                }} />

            <TextInput field={'work_address'} label={'تاريخ الميلاد الهجرى'}
                disabled
                defaultValue={user.member.birthdate_g}
                rules={{
                    required: true
                }} />


            <TextInput field={'work_address'} label={'تاريخ الميلاد الميلادى'}
                disabled
                defaultValue={user.member.birthdate_h}
                rules={{
                    required: true
                }} />

        </div>
    )
}

export default ProfileShow