import { Grid } from "@mui/material"
import { Container } from "@mui/system"
import Item from "./Item";
const Gallery = ({items}) => {
  return (

    <section className="gallery">
      <h2 className="intro-heading text-center">
        الألبومات
      </h2>
      <Container>
        {
          items &&

          <Grid container spacing={3}>
            {items.map((item: any) => (
              <Grid item xs={12} sm={4}>
                <Item image={item.image} title={item.title} id={item.id} />
              </Grid>
            ))}
          </Grid>
        }
      </Container>
    </section>
  )
}

export default Gallery