import { Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Intro from '../../components/Intro'
import header from '../../assets/images/offers/header.png';
import offer1 from '../../assets/images/offers/offer1.png';
import offer2 from '../../assets/images/offers/offer2.png';
import DialogAdd from '../../components/Dialog/Add';
import AddOffer from '../../components/FormsWebsite/Offers/Add';
import AnimatedPage from '../../components/Animations/AnimatedPages';
import { useEffect, useState } from 'react';
import { indexData } from '../../plugins/api';
import DialogFire from '../../components/Dialog/Fire';
import Button from '../../components/Controls/Button';
import DialogSuccess from '../../components/Dialog/Success';


const Offers = () => {
    const [confirmDialogSuccess, setConfirmDialogSuccess] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialogSuccess = () => {
        setConfirmDialogSuccess({
            isOpen: true,
            title: 'تم إرسال طلب المناسبة للإدارة بنجاح',
            subTitle: 'سوف يتم مراجعة بيانات ملاحظاتك مع الإدارة وإرسال الرد لك',
            onConfirm: () => { }
        })
    }


    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = () => {
        setConfirmDialog({
            isOpen: true,
            title: ' إضافة عرض جديد',
            subTitle: '',
            onConfirm: () => { }
        })
    }
    const [offers, setOffers] = useState([]);

    const getOffers = () => {
        indexData({ resource: `mobile/offer` }).then((res) => {
            const offers = res.data.data.items
            setOffers(offers);
          
        });
    };

    const fetchData = () => {
        getOffers()
        setConfirmDialog({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })
        openDialogSuccess()
    };

    useEffect(() => {
        getOffers()
    }, []);

    return (
        <AnimatedPage>
            <DialogSuccess confirmDialog={confirmDialogSuccess} setConfirmDialog={setConfirmDialogSuccess}></DialogSuccess>
            <section className='offers page'>
                <Intro image={header} />
                <div className='offers__content'>
                    <Container>
                        <Grid item xs container direction="row-reverse" spacing={2}>

                            <Button text={'اضافة عرض'}  onClick={openDialog}>
                                <Typography className='icon-btn' fontWeight={'bolder'} marginX={1} >+</Typography>
                            </Button>

                            <DialogFire btnName="تأكيد التبرع" confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}>
                                <AddOffer fetchData={fetchData}></AddOffer>
                            </DialogFire>

                        </Grid>
                        {offers.map((item: any) => (
                            <div className='offers__content__card'>
                                <div className='offers__content__card__media'>
                                    <img src={item.image} alt="offer" />
                                </div>
                                <Grid item xs container direction="row-reverse" spacing={2}>
                                    <span className='hint'>
                                        العرض مقدم من {item.added_by}
                                    </span>
                                </Grid>
                                <Grid item>

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid item className='item-events'>
                                            <Typography className='font-bolder' variant="h5" component="h2">
                                                {item.title}
                                            </Typography>
                                        </Grid>

                                        <Grid item className='item-events'>
                                            <Typography color={'#9F7D4F'} className='font-bolder' variant="h6" component="h2">

                                                صالح حتى {item.end}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <p>
                                        {item.full_description}
                                    </p>


                                </Grid>
                            </div>
                        ))}
                    </Container>
                </div>

            </section>
        </AnimatedPage>
    )
}

export default Offers