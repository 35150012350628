import { useFormContext } from "react-hook-form";
import TextField from '@mui/material/TextField';

import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";


const RadioInput = ({ field, label, values, defaultValue, rules, ...other }) => {

  const { register, formState: { errors } } = useFormContext();
  const { t } = useTranslation();

  return (
    <div>

      <div className="check-options mt-1">
        <FormLabel>
          {label}
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={defaultValue}
          name="radio-buttons-group"
          {...other}
          {...register(field, rules)}
        >
          <Grid container>
            {values.map((value: any) => (
              <Grid item xs={6} key={value.value}>
                <FormControlLabel value={value.value} control={<Radio />} label={value.label} />
              </Grid>
            ))}

          </Grid>
        </RadioGroup>

      </div>
    </div>

  );
};

export default RadioInput;
