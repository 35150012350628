import { Link } from "react-router-dom";

const SliderCard = ({ banner, page, image, SliderType }) => {

    return (
        
        <Link to={page}>
            <div className={'slider__item ' + SliderType}>
                {banner && <p className="slider__item__banner"> {banner} </p>}
                <img src={image} alt="image1" />
            </div>
        </Link>
    )
}

export default SliderCard