import { Container, Grid, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import logo from '../../../assets/images/home/logo.svg';
import android from '../../../assets/images/home/android.png';
import apple from '../../../assets/images/home/apple.png';
import { NavLink, useNavigate } from "react-router-dom";
import { indexData } from "../../../plugins/api";
import { useState, useEffect } from "react"
const AppFooter = () => {
    const navigate = useNavigate();

    const [item, setItem] = useState(null)

    const getItem = async () => {
        await indexData({ resource: `mobile/settings` }).then((res) => {
            const item = res.data.data;
            setItem(item);
        });
    };

    useEffect(() => {
        getItem();
    }, []);


    return (
        <footer>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                        <NavLink to={'/'}>
                            <img className='logo' src={logo} alt="logo" />
                        </NavLink>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <List>
                            <ListItem disablePadding className="title-routes">
                                <Typography color={'#fff'} variant="h5" component="h2">
                                    مساعدة & دعم
                                </Typography>
                            </ListItem>
                            {
                            [
                                { title: 'الطلبات', link: '/requests' },
                             { title: 'حسابات الصندوق', link: '/donations' }, 
                             { title: 'المدونة', link: '/blogs' }
                            
                            ]
                             .map((item, index) => (
                                <ListItem key={item.title} disablePadding>
                                    <ListItemButton className="px-0" onClick={() => navigate(item.link)} >
                                        <ListItemText primary={item.title} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {
                            item
                            &&
                            <List className="contacts">
                                <ListItem disablePadding>
                                    <Typography color={'#fff'} variant="h5" component="h2">
                                        تواصل معنا
                                    </Typography>
                                </ListItem>


                                <ListItem disablePadding>
                                    <ListItemText primary={`جوال: ${item.phone}`} />

                                </ListItem>

                                <ListItem disablePadding>

                                    <ListItemText primary={` العنوان: ${item.address}`} />
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemText primary={`ميل: ${item.email}`} />
                                </ListItem>

                            </List>
                        }
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <List className="contacts">
                            <ListItem disablePadding>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.hulul.azharfamily">
                                    <img src={android} alt="android" />
                                </a>
                            </ListItem>

                            <ListItem disablePadding>
                                <a target="_blank" href="https://apps.apple.com/eg/app/%D8%B9%D8%A7%D8%A6%D9%84%D8%A9-%D8%A7%D9%84%D8%A7%D8%B2%D9%87%D8%B1/id6443561128">
                                    <img src={apple} alt="apple" />
                                </a>
                            </ListItem>


                        </List>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    )
}


export default AppFooter;