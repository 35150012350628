
import { FormControl, FormLabel, Grid, TextField } from "@mui/material";
import { ChangeEvent, useRef, useState } from 'react';
import { useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import fileImage from "../../../assets/images/icons/file.svg"
const UploadFile = ({ label, field, rules, title, accept }) => {

    const { t } = useTranslation();
    const inputElement = useRef();
    const { register, formState: { errors } } = useFormContext();

    const [file, setFile] = useState<File>();

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        let file = e.target.files[0]
        let src = URL.createObjectURL(file);


        console.log(src)
        if (file) {
            setFile(file);
        }

    };

    const handleUploadClick = () => {
        if (!file) {
            return;
        }
    }


    return (
        <div>
            <FormLabel>
                {title}
            </FormLabel>
            <div className="file-upload">
                <FormControl fullWidth variant="outlined" >

                    <input  {...register(field, rules)} accept={accept} type="file" onChange={handleFileChange} />
                    <Grid container alignItems={'center'} spacing={'2'}>
                        <Grid item >
                            <img id="image" ref={inputElement} src={fileImage} alt="file" />
                        </Grid>
                        <Grid item>
                            {!file && <p>
                                {label}
                            </p>
                            }
                            <div>{file && `${file.name}`}</div>
                        </Grid>
                    </Grid>
                    <div className="mr-validation">
                        {errors[field] &&
                            <span style={{ color: "red" }}>
                                {t(errors[field].type)}
                            </span>
                        }
                    </div>
                </FormControl>
            </div>
        </div>
    )
}

export default UploadFile