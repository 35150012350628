import { Button, ButtonBase, Grid, Paper, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Intro from '../../../components/Intro'
import Item from '../../../components/Blog'
import header from '../../../assets/images/donations/header.png';
import AnimatedPage from '../../../components/Animations/AnimatedPages';
import { useState, useEffect } from 'react';
import DialogFire from '../../../components/Dialog/Fire';

import { indexData } from '../../../plugins/api';
import AddDonation from '../../../components/FormsWebsite/Donations/Add';
import DialogSuccess from '../../../components/Dialog/Success';


const New = () => {

    const [confirmDialogSuccess, setConfirmDialogSuccess] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialogSuccess = () => {
        setConfirmDialogSuccess({
            isOpen: true,
            title: 'تم إرسال التبرع  للإدارة بنجاح',
            subTitle: 'سوف يتم مراجعة بيانات ملاحظاتك مع الإدارة وإرسال الرد لك',
            onConfirm: () => { }
        })
    }
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = () => {
        setConfirmDialog({
            isOpen: true,
            title: 'تقديم طلب تبرع',
            subTitle: '',
            onConfirm: () => { alert("hi") }
        })
    }
    const [item, setItem] = useState(null)
    const [items, setItems] = useState(null)

    const getItems = async () => {
        await indexData({ resource: `mobile/get-bank-account` }).then((res) => {
            const items = res.data.data.items;
            setItems(items);
        });
    };

    useEffect(() => {
        getItems();
    }, []);

    const fetchData = () => {
        getItems()
        setConfirmDialog({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })
        openDialogSuccess()
    };



    return (
        <AnimatedPage>
        <DialogSuccess confirmDialog={confirmDialogSuccess} setConfirmDialog={setConfirmDialogSuccess}></DialogSuccess>
            <section className='donations page bg-white'>
                <Intro image={header} />
                <div className='page__content mt-3'>
                    <div className='donations__prev'>
                        <Container>
                            <Typography className='font-bolder' gutterBottom variant="h5" component="h2">
                                تبرعات جديدة
                            </Typography>
                            {items && items.map((item: any) => (
                                <div key={item.id}>
                                    <DialogFire btnName="تأكيد التبرع" confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}>
                                        <AddDonation id={item.id} fetchData={fetchData} ></AddDonation>
                                    </DialogFire>

                                    <Paper className='donations__prev__card paper'
                                        sx={{
                                            p: 1,
                                            margin: 'auto',
                                            flexGrow: 1,
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm container>
                                                <Grid item xs container direction="row" spacing={2}>
                                                    <Grid item md={10}>
                                                        <Typography className='donations__prev__card__text font-bolder' gutterBottom >
                                                            <span >
                                                                اسم الحساب:
                                                            </span>
                                                            <span className='text-primary'>
                                                                {item.name}
                                                            </span>
                                                        </Typography>
                                                        <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                            <span >
                                                                رقم الحساب:
                                                            </span>
                                                            <span className='text-primary'>
                                                                {item.account_number}
                                                            </span>
                                                        </Typography>

                                                        <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                            <span >
                                                                اسم البنك:
                                                            </span>

                                                            <span className='text-primary'>
                                                                {item.name}
                                                            </span>
                                                        </Typography>
                                                        <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                            <span >
                                                                رقم الحساب الدولي:                                              </span>
                                                            <span className='text-primary'>
                                                                {item.iban}
                                                            </span>
                                                        </Typography>
                                                        <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                            <span >
                                                                سويفت      :                                        </span>
                                                            <span className='text-primary'>
                                                                {item.swift_no}
                                                            </span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item container flexDirection='column' alignItems='center' justifyContent='center' alignContent='center' md={2}>
                                                        <Typography variant="subtitle1" className='text-left font-bolder donate-btn' component="div">
                                                            <Button variant='contained' onClick={() => {
                                                                openDialog()
                                                            }}>
                                                                تبرع الآن
                                                            </Button>
                                                        </Typography>
                                                    </Grid>

                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </div>
                            ))}
                        </Container>
                    </div>
                </div>

            </section>
        </AnimatedPage>
    )
}

export default New