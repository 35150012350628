import React, { useEffect, useState } from 'react'
import Intro from '../../../components/Intro'
import header from '../../../assets/images/events/header.png';
import eventImage from '../../../assets/images/events/event-details.png';



import { Box, Container } from '@mui/system';
import { Button, ButtonBase, FormControl, Grid, InputLabel, OutlinedInput, Paper, Typography } from '@mui/material';
import Comment from '../../../components/Blog/Comment';
import commentor from '../../../assets/images/blogs/commentor.svg';
import send from '../../../assets/images/blogs/send.svg';
import AnimatedPage from '../../../components/Animations/AnimatedPages';
import { useParams } from 'react-router-dom';
import { showData } from '../../../plugins/api';


const OfferDetails = () => {

    const { id } = useParams()
    const [item, setItem] = useState(null)

    const getItem = async () => {
        await showData({ resource: `mobile/offer`, param: id }).then((res) => {
            const item = res.data.data;
            setItem(item);
        });
    };

    useEffect(() => {
        getItem();
    }, []);



    return (
        <AnimatedPage>
            <section className='events page'>
                <Intro image={header} />
                <div className='offers__content'>
                    <Container>
               
                        { item &&
                            <div className='offers__content__card'>
                                <div className='offers__content__card__media'>
                                    <img src={item.image} alt="offer" />
                                </div>
                                <Grid item xs container direction="row-reverse" spacing={2}>
                                    <span className='hint'>
                                        العرض مقدم من {item.added_by}
                                    </span>
                                </Grid>
                                <Grid item>

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid item className='item-events'>
                                            <Typography className='font-bolder' variant="h5" component="h2">
                                                {item.title}
                                            </Typography>
                                        </Grid>

                                        <Grid item className='item-events'>
                                            <Typography color={'#9F7D4F'} className='font-bolder' variant="h6" component="h2">

                                                صالح حتى {item.end}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <p>
                                        {item.full_description}
                                    </p>


                                </Grid>
                            </div>
                        }
                    </Container>
                </div>

            </section>
        </AnimatedPage>
    )
}

export default OfferDetails