import { Button, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Intro from '../../components/Intro'
import Item from '../../components/Blog'
import header from '../../assets/images/opinions/header.png';
import opinion from '../../assets/images/opinions/opinion.svg';
import vote from '../../assets/images/opinions/vote.svg';

import Action from '../../components/DonationAction';
import AnimatedPage from '../../components/Animations/AnimatedPages';
const Survey = () => {
    return (
        <AnimatedPage>
            <section className='opinions page bg-white'>
                <Intro image={header} />
                <div className='page__content text-center mt-3'>
                    <Container>
                        <Grid container spacing={8} justifyContent='center'>
                            <Grid item xs={12} sm={6}>
                                <Action
                                    page={'/survey/opinions'}
                                    image={opinion}
                                    title='استطلاعات رأي'
                                    description='هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، '
                                />

                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Action
                                    page={'/survey/votes'}
                                    image={vote}
                                    title='انتخابات'
                                    description='هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، '
                                />

                            </Grid>


                        </Grid>


                    </Container>
                </div>

            </section>
        </AnimatedPage>
    )
}

export default Survey