import { Fragment, useEffect, useState } from "react"
import About from "../../components/Home/About"
import Hero from "../../components/Home/Hero"
import Slider from "../../components/Home/Slider"
import tree1 from '../../assets/images/home/tree1.png';
import events from '../../assets/images/home/events.png';
import offers from '../../assets/images/home/offers.png';
import Gallery from "../../components/Home/Gallery";
import MobileApp from "../../components/Home/MobileApp";
import AnimatedPage from "../../components/Animations/AnimatedPages";
import Preloader from "../../components/Preloader";

import { deleteData, indexData } from '../../plugins/api';
import SliderCard from "../../components/Home/Slider/Card";
import SliderWrapper from "../../components/Home/Slider/Wrapper";

const Home = () => {
    const [offers, setOffers] = useState([]);
    const [events, setEvents] = useState([]);
    const [galleries, setGalleries] = useState([]);
    const [familes, setFamilies] = useState([]);

    const getOffers = () => {
        indexData({ resource: `mobile/offer` }).then((res) => {
            const offers = res.data.data.items
            setOffers(offers);
        });
    };

    const getEvents = () => {
        indexData({ resource: `mobile/events` }).then((res) => {
            const events = res.data.data.items
            setEvents(events);
        });
    };


    const getGalleries = () => {
        indexData({ resource: `mobile/media` }).then((res) => {
            const galleries = res.data.data.items
            setGalleries(galleries);
        });
    };


    const getFamilies = () => {
        indexData({ resource: `mobile/get-family` }).then((res) => {
            const families = res.data.data.items
            setFamilies(families);
        });
    };


    useEffect(() => {
        getOffers()
        getEvents()
        getGalleries()
        getFamilies()

    }, []);


    return (
        <AnimatedPage>
            <Hero />
            {/* {<Slider title={'شجرة العيلة'} SliderType={'/'} items={offers} />} */}

            {
                familes.map((item: any) => (
                    <About key={item.id} item={item} />
                ))
            }

            <Slider page={'/events'} title={'الفعاليات'} SliderType={'events'} items={events} />
            <Slider page={'/offers'} title={'العروض'} SliderType={'offers'} items={offers} />
            <Gallery items={galleries} />
            <MobileApp />

        </AnimatedPage>
    )
}

export default Home