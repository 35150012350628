import { useFormContext } from "react-hook-form";
import TextField from '@mui/material/TextField';

import { Autocomplete, FormControl, FormLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextInputX from "./TextInputX";


const SelectInput = ({ field, label, options,keyShow, setValue, rules, ...other }) => {

  const { register, formState: { errors } } = useFormContext();
  const { t } = useTranslation();

  return (
    <div>

      <FormControl fullWidth variant="outlined">

        <Autocomplete
          fullWidth
          disablePortal
          id="combo-box-demo"
          options={options}

          getOptionLabel={(option: any) => {
            setValue(option.id);
            return option[keyShow]

          }}
          renderInput={(params) => <TextInputX
            {...other}
            fullWidth
            error={errors[field] ? true : false}
            {...register(field, rules)}
            label={label + '...'}
            {...params} field={field} rules={{}} />}
        />
        <div>
          {errors[field] &&
            <span style={{ color: "red" }}>
              {t(errors[field].type)}
            </span>
          }
        </div>
      </FormControl>
    </div>

  );
};

export default SelectInput;
