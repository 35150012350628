import { useFormContext } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { Fragment, useState } from "react";

import { FormControl, FormLabel, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { useTranslation } from "react-i18next";
import Close from "../../assets/images/icons/close.svg";
import Eye from "../../assets/images/icons/eye.png";

const PasswordInput = ({ field, label, rules, ...other }) => {

  const { register, formState: { errors } } = useFormContext();
  const { t } = useTranslation();
  
  const [showPassword, setShowPassword] = useState(false);
 
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
};

  return (
    <div>
      <FormLabel>
        {label}
      </FormLabel>
      <FormControl fullWidth variant="outlined" >
      <InputLabel htmlFor="outlined-adornment-password"> {label}... </InputLabel>
        <OutlinedInput
          {...other}
          error={errors[field] ? true : false}
          {...register(field, rules)}
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <img width={25} src={Eye} alt="eye" /> : <img src={Close} alt="close" />}
              </IconButton>
            </InputAdornment>
          }
          label={label}
        />
        <div>
          {errors[field] &&
            <span style={{ color: "red" }}>
              {t(errors[field].type)}
            </span>
          }
        </div>
      </FormControl>
    </div>

  );
};

export default PasswordInput;
