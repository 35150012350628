import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import logo from '../../../assets/images/home/logo.svg';
import notification from '../../../assets/images/icons/notification.svg';
import avatar from '../../../assets/images/icons/avatar.svg';
import { ClickAwayListener, Container, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import Controls from '../../Controls/Controls';
import { useNavigate } from "react-router-dom";

import { NavLink } from "react-router-dom";
import Button from '../../Controls/Button';
import { indexData } from '../../../plugins/api';





const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100%)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function PersistentDrawerRight() {
    const navigate = useNavigate();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    setTimeout(() => {
        const topBtn = () => {
            const topBtnFunc = () => {
                let scrollpos = window.scrollY;
                const nav = document.querySelector("header");
                if (scrollpos > 50) {
                    nav.classList.add('sticky')
                } else {
                    nav.classList.remove('sticky')
                }
            }
            topBtnFunc()
            window.addEventListener("scroll", function () {
                topBtnFunc()
            });
        }
        topBtn()
    }, 1000)


    const [openMenu, setOpenMenu] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        setOpenMenu((prevOpen) => !prevOpen);
        getItems()
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        getItems()
        getItem()
        setOpenMenu(false);

    };

    const [openMenuUser, setOpenMenuUser] = React.useState(false);
    const anchorRefUser = React.useRef<HTMLButtonElement>(null);

    const handleToggleUser = () => {
        setOpenMenuUser((prevOpen) => !prevOpen);
    };

    const handleCloseUser = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRefUser.current &&
            anchorRefUser.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        setOpenMenuUser(false);
    };



    const [items, setItems] = React.useState([]);
    const getItems = () => {
        indexData({ resource: `mobile/notifications` }).then((res) => {
            const items = res.data.data.items
            setItems(items);
        });
    };


    const [item, setItem] = React.useState([]);
    const getItem = () => {
        indexData({ resource: `mobile/unread-notifications` }).then((res) => {
            const item = res.data.data.count
            setItem(item);
        });
    };


    React.useEffect(() => {
        getItem()
    }, []);

    const user = JSON.parse(window.localStorage.getItem("user"));

    const logout = () => {
        window.localStorage.clear()
        setTimeout(() => navigate('/auth/login'), 10)
    }


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {user &&
                <AppBar position="fixed" open={open}>
                    <Container >
                        <Toolbar>
                            <NavLink to={'/'}>
                                <img className='logo' src={logo} alt="logo" />
                            </NavLink>
                            <List className='items-header'>
                                {[{ title: 'الرئيسية', link: '/' }, { title: 'الطلبات', link: '/requests' },
                                 { title: 'حسابات الصندوق', link: '/donations' },
                                  { title: 'استطلاعات الرأي', link: '/survey' },
                                   { title: 'المدونة', link: '/blogs' }].map((item, index) => (
                                    <ListItem className={`items-header__item  itemlink-${index}`} key={index} disablePadding>
                                        <NavLink
                                            className='items-header__item__link'
                                            to={item.link}

                                        >
                                            {item.title}
                                        </NavLink>

                                    </ListItem>
                                ))}
                                {/* <ListItem disablePadding className='items-header__btns-container'>
                                <Controls.Button
                                    text="تسجيل دخول"
                                    onClick={() => navigate('/auth/login')} />
                                <Controls.Button
                                    text="إنشاء حساب"
                                    onClick={() => navigate('/auth/register')} />
                            </ListItem> */}

                                <ListItem disablePadding className='items-header__btns-container'>


                                    <div>
                                        <div
                                            className='notify-menu'
                                            ref={anchorRef}
                                            id="composition-button"
                                            aria-controls={openMenuUser ? 'composition-menu' : undefined}
                                            aria-expanded={openMenuUser ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleToggleUser}
                                        >
                                            {
                                                user.member.image &&
                                                <img id="img-user" width={'50px'} height={'50px'} src={user.member.image} alt="avatar" />
                                            }

                                            {
                                                user.member.image == null &&
                                                <img id="img-user" width={'50px'} height={'50px'} src={avatar} alt="avatar" />
                                            }


                                            <Typography fontWeight={'bolder'}>
                                                {user.name}
                                            </Typography>
                                            <Popper
                                                open={openMenuUser}
                                                anchorEl={anchorRef.current}
                                                role={undefined}
                                                placement="bottom-start"
                                                transition
                                                disablePortal
                                            >
                                                {({ TransitionProps, placement }) => (
                                                    <Grow
                                                        {...TransitionProps}
                                                        style={{
                                                            transformOrigin:
                                                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                                                        }}
                                                    >
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={handleCloseUser}>
                                                                <div >
                                                                    <MenuList
                                                                        autoFocusItem={openMenuUser}
                                                                        id="composition-menu"
                                                                        aria-labelledby="composition-button"

                                                                    >
                                                                        <MenuItem onClick={() => navigate('/offers')}>
                                                                            العروض
                                                                        </MenuItem>
                                                                        <MenuItem onClick={() => navigate('/events')}>
                                                                            الفعاليات
                                                                        </MenuItem>
                                                                        <MenuItem onClick={() => navigate('/gallery')}>
                                                                            الوسائط
                                                                        </MenuItem>
                                                                        <MenuItem className='link-tree'>
                                                                            <a target='_blank' href="https://dashboard.alazharfamily.com/tree">
                                                                                شجرة العيلة
                                                                            </a>
                                                                        </MenuItem>
                                                                        <Divider></Divider>
                                                                        <MenuItem onClick={() => navigate('/profile')}>
                                                                            الصفحة الشخصية
                                                                        </MenuItem>
                                                                        <Divider></Divider>
                                                                        <MenuItem onClick={logout}>تسجيل الخروج</MenuItem>


                                                                    </MenuList>
                                                                </div>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                        </div>

                                    </div>
                                </ListItem>
                                <ListItem>


                                    <div>
                                        <span
                                            className='notify-menu'
                                            ref={anchorRef}
                                            id="composition-button"
                                            aria-controls={openMenu ? 'composition-menu' : undefined}
                                            aria-expanded={openMenu ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleToggle}
                                        >
                                            <span className='count'>{item}</span>
                                            <img width={'50px'} height={'50px'} src={notification} alt="notification" />
                                        </span>
                                        <Popper
                                            open={openMenu}
                                            anchorEl={anchorRef.current}
                                            role={undefined}
                                            placement="bottom-start"
                                            transition
                                            disablePortal
                                        >
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin:
                                                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                                                    }}
                                                >
                                                    <Paper>
                                                        <ClickAwayListener onClickAway={handleClose}>
                                                            <div className='menu-list-items'>
                                                                <MenuList
                                                                    autoFocusItem={openMenu}
                                                                    id="composition-menu"
                                                                    aria-labelledby="composition-button"

                                                                >
                                                                    {items.map((item: any) => (
                                                                        <>
                                                                            <MenuItem onClick={handleClose}>{item.data.message}</MenuItem>
                                                                            <Divider></Divider>
                                                                        </>
                                                                    ))}
                                                                </MenuList>
                                                            </div>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </div>
                                </ListItem>
                            </List>
                            <IconButton
                                className='icon-mobile'
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerOpen}
                                sx={{ ...(open && { display: 'none' }) }}
                            >
                                <MenuIcon />
                            </IconButton>

                        </Toolbar>

                    </Container>
                </AppBar>
            }

            {
                open &&
                <div className='drop-off' onClick={handleDrawerClose}>
                </div>
            }


            <Drawer className='drawer-mobile'
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {[{ title: 'الرئيسية', link: '/' }, { title: 'المدونة', link: '/blogs' }, { title: 'استطلاعات الرأي', link: '/survey' }, { title: 'حسابات الصندوق', link: '/requests' }
                        , { title: 'الطلبات', link: '/requests' }, { title: 'العروض', link: '/offers' },
                        , { title: 'الفعاليات', link: '/events' }, { title: 'الألبومات', link: '/gallery' }, { title: 'الصفحة الشخصية', link: '/profile' }
                    ].map((item, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton onClick={() => navigate(item.link)}>

                                <ListItemText className='text-right' primary={item.title} />
                            </ListItemButton>
                        </ListItem>

                    ))}
                </List>
                <List>
                    <ListItem  disablePadding>
                        <MenuItem className='link-family-mobile'>
                            <a target='_blank' href="https://dashboard.alazharfamily.com/tree">
                                شجرة العيلة
                            </a>
                        </MenuItem>
                    </ListItem>
                </List>


            </Drawer>

        </Box >



    );
}