import { useFormContext } from "react-hook-form";
import TextField from '@mui/material/TextField';

import { FormControl, FormLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { Dayjs } from 'dayjs';
import { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const DateInput = ({ field, label, rules, ...other }) => {
  const { register, formState: { errors } } = useFormContext();
  const [value, setValue] = useState(null);
  const { t } = useTranslation();

  const changeValue = (newValue) => {

    let date = new Date(newValue).toLocaleString().split('-')
    let dateFormat=date[2]+"/"+date[1]+"/"+date[0]
    console.log(dateFormat)
    setValue(dateFormat);
  }

  return (
    <div>
      <FormLabel>
        {label}
      </FormLabel>
      <FormControl fullWidth variant="outlined" >
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={label}
            value={value}
            {...register(field, rules)}
            onChange={(newValue) => {
              changeValue(newValue)
              {<TextField value={value} {...register(field, rules)} error={errors[field] ? true : false}
             />}
            }}
            renderInput={(params) => <TextField value={value} {...register(field, rules)} error={errors[field] ? true : false}
              {...params} />}
          />
        </LocalizationProvider> */}

        <TextField
        className="date-cs"
          {...other}
          onChange={changeValue}
          fullWidth
          error={errors[field] ? true : false}
          {...register(field, rules)}
       
          type="date"

        />

        <div>
          {errors[field] &&
            <span style={{ color: "red" }}>
              {t('validations.' + errors[field].type)}
            </span>
          }
        </div>
      </FormControl>
    </div>

  );
};

export default DateInput;
