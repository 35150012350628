import { Box, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup, TextField } from "@mui/material"
import { Container } from "@mui/system";
import { FormProvider } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import logo from '../../../assets/images/home/logo.svg';
import AnimatedPage from "../../../components/Animations/AnimatedPages";
import Preloader from "../../../components/Preloader";
import FormLogin from "../../../components/Login/Form";

const Login = () => {
    setTimeout(() => {
        window.localStorage.clear()
    }, 500)
    return (
        <AnimatedPage>
            <Preloader />
            <section className='auth'>
                <Grid container spacing={1}>
                    <Grid className="auth__right" item xs={12} sm={6}>
                        <div >
                            <Container>
                                <div className="text-center">
                                    <Link to="/" >
                                        <img className='logo' src={logo} alt="logo" />
                                    </Link>
                                </div>
                                <h2>
                                    تسجيل الدخول
                                </h2>
                                <div>
                                    <FormLogin />
                                </div>
                            </Container>
                        </div>
                    </Grid>
                    <Grid className="auth__left" item xs={12} sm={6}>
                    </Grid>
                </Grid>
            </section>
        </AnimatedPage>
    )
}

export default Login