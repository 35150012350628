import { Box, Button, FormControl, FormLabel, Grid, TextField } from "@mui/material"
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import logo from '../../../assets/images/home/logo.svg';
import AnimatedPage from "../../../components/Animations/AnimatedPages";
import Form from "../../../components/Login/Form";
import Preloader from "../../../components/Preloader";
import { useNavigate } from "react-router-dom";
import { storeOrUpdate } from "../../../plugins/api";
import { useState } from "react";

const ChooseVerify = () => {
    const navigate = useNavigate();
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const getUser = JSON.parse(window.localStorage.getItem("user"));

    const sendOtp = (type) => {

        let fd = new FormData();
        if(type=='email'){
            fd.append("email", getUser.member.email)
        }else{
            fd.append("email", getUser.member.phone)
        }
 

        let actionRoute = { resource: `auth/resend_code`, param: null, data: fd, method: 'post' };
  
        return storeOrUpdate(actionRoute).then(
            (response) => {
                window.location.href=`/auth/verify/${type}`
            //  navigate(`/auth/verify/${type}`)
            }
        ).catch((errors) => {
            setNotify({
                isOpen: true,
                message: errors.response.data.msg,
                type: 'error'
            })
        })
    }


    return (
        <AnimatedPage>
            <Preloader />
            <section className='auth'>
                <Grid container spacing={1}>
                    <Grid className="auth__right" item xs={12} sm={6}>
                        <div >
                            <Container>
                                <div className="text-center mt-3">
                                    <Link to="/" >
                                        <img className='logo' src={logo} alt="logo" />
                                    </Link>
                                </div>
                                <h2 className="mt-1 text-center">
                                    اختيار طريقة التفعيل
                                </h2>
                          
                                <Box p={1} mt={5}>
                                    <Grid container lg={12} justifyContent="center">
                                        <Grid item mb={3}>
                                            <Button type="submit" variant="contained" onClick={() => sendOtp('email')}>
                                                البريد الألكترونى
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button type="submit" variant="contained" onClick={() => sendOtp('phone')}>
                                                رقم الجوال
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Container>
                        </div>
                    </Grid>
                    <Grid className="auth__left" item xs={12} sm={6}>
                    </Grid>
                </Grid>
            </section>
        </AnimatedPage>
    )
}

export default ChooseVerify