import { Autocomplete, FormControl, FormLabel, Grid, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import dummyData from '../../../../plugins/data/data';
import UploadFile from '../../../Forms/UploadFile';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { storeOrUpdate } from '../../../../plugins/api';

import TextInputX from '../../../Forms/TextInputX';
import Button from '../../../Controls/Button';
import Notification from '../../../Notifications/Notification';
import DateInput from '../../../Forms/DateInput';


const AddDonation = ({ fetchData, id }) => {
    const defaultValues = null;
    const [value, setValue] = useState<Dayjs | null>(null);
    const formMethods = useForm<FormData>({
        defaultValues: defaultValues,
    });
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const { handleSubmit, setError } = formMethods;


    const onFormSubmit = async (data: any) => {

        let fd = new FormData();
        const keys = Object.keys(data);
        console.log(data)
        keys.forEach((key) => {
            if (key != 'image') {
                fd.append(key, data[key])
            }
            if (key == 'image') {
                fd.append(key, data[key][0])
            }
        });


        let actionRoute = { resource: `mobile/add-account/${id}`, param: null, data: fd, method: 'post' };
        if (defaultValues) {
            actionRoute = { resource: `mobile/add-account`, param: defaultValues.id, data: data, method: 'put' };
        }
        return storeOrUpdate(actionRoute).then(
            (response) => {
                fetchData()
            }
        ).catch((errors) => {
            setNotify({
                isOpen: true,
                message: errors.response.data.msg,
                type: 'error'
            })
        })

    };

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onFormSubmit)}>


                <UploadFile accept={'*'} title={'الملف'} rules={{
                    required: true
                }} field={'image'} label={'يرجى إرفاق ملف التبرع الذى تريده'} />


                <TextInputX showLabel={false} type="number" field={'price'} label={'قيمة التبرع '}
                    rules={{
                        required: true,
                    }} />



                <TextInputX field={'note'} label={'ملاحظات'}
                    multiline
                    rows={1}
                    defaultValue=""
                    rules={{
                        required: true
                    }} />





                <Grid container justifyContent={'center'}>
                    <Grid item>
                        <Button type='submit' className='btn-popup' variant='contained' autoFocus >
                            اضافة تبرع
                        </Button>
                    </Grid>
                </Grid>

                <Notification
                    notify={notify}
                    setNotify={setNotify}
                />
            </form>
        </FormProvider >

    )
}

export default AddDonation