import { Button, ButtonBase, Grid, Paper, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Intro from '../../../components/Intro'
import Item from '../../../components/Blog'
import header from '../../../assets/images/donations/header.png';
import AnimatedPage from '../../../components/Animations/AnimatedPages';
import { indexData } from '../../../plugins/api';
import { useEffect, useState } from 'react';


const DonationPrevious = () => {

    const [items, setItems] = useState(null)

    const getItems = async () => {
        await indexData({ resource: `mobile/get-account` }).then((res) => {
            const items = res.data.data.items;
            setItems(items);
        });
    };

    useEffect(() => {
        getItems();
    }, []);





    return (
        <AnimatedPage>
            <section className='donations page bg-white'>
                <Intro image={header} />
                <div className='page__content mt-3'>
                    <div className='donations__prev'>
                        <Container>
                            <Typography className='font-bolder' gutterBottom variant="h5" component="h2">
                                تبرعات سابقة
                            </Typography>


                            {items && items.map((item: any) => (
                                <Paper className='donations__prev__card paper'
                                    sx={{
                                        p: 1,
                                        margin: 'auto',
                                        flexGrow: 1,
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs={10}>
                                                    {/* <div>
                                                        <img width="40" height="40" src={item.image}  />
                                                    </div> */}
                                                    <Typography className='donations__prev__card__text font-bolder' gutterBottom >
                                                        <span >
                                                            التاريخ:
                                                        </span>
                                                        <span className='text-primary'>
                                                            {item.created_at.split(' ')[0]}
                                                        </span>
                                                    </Typography>
                                                    <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                        <span >
                                                            الوقت:
                                                        </span>
                                                        <span className='text-primary'>
                                                            {item.created_at.split(' ')[1]}
                                                        </span>
                                                    </Typography>

                                                    <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                        <span >
                                                            قيمة التبرع:
                                                        </span>

                                                        <span className='text-primary'>
                                                            {item.price}
                                                            ريال
                                                        </span>
                                                    </Typography>
                                                    {


                                                        <Typography className='donations__prev__card__text font-bolder' gutterBottom>
                                                            <span >
                                                                ملاحظات:                                                 </span>
                                                            <span className='text-primary'>
                                                                {item.admin_response}
                                                            </span>
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid item className='donations__prev__card__notify' xs={2}>
                                                    <Typography variant="subtitle1" className='text-center font-bolder' component="div">
                                                        <span className='status'>
                                                            حالة التحويل
                                                        </span>
                                                        <br />
                                                        {
                                                            item.status == 'تحت المراجعه'
                                                            &&
                                                            <span className='text-pending'>
                                                                {item.status}
                                                            </span>
                                                        }
                                                        {
                                                            item.status == 'تم القبول'
                                                            &&
                                                            <span className='text-success'>
                                                                {item.status}
                                                            </span>
                                                        }
                                                        {
                                                            item.status == 'مرفوض'
                                                            &&
                                                            <span className='text-danger'>
                                                                {item.status}
                                                            </span>
                                                        }
                                                    </Typography>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Paper>

                            ))}

                        </Container>
                    </div>
                </div>

            </section>
        </AnimatedPage>
    )
}

export default DonationPrevious