
const SliderWrapper = ({ title, SliderType, children }) => {
    return (
        <section className={'slider tree text-center text-primary' + SliderType}>
            <h2 className="intro-heading mt-3">
                {title}
            </h2>
            {children}

        </section>
    )
}

export default SliderWrapper