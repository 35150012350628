import { Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import * as React from 'react';
import { Dayjs } from 'dayjs';
import all from '../../../../assets/images/icons/public.svg';
import only_me from '../../../../assets/images/icons/private.svg';
import male from '../../../../assets/images/icons/gender.svg';
import female from '../../../../assets/images/icons/female.svg';
import Button from '../../../Controls/Button';
import { storeOrUpdate } from '../../../../plugins/api';


const ProfilePrivacy = ({ akey ,fetchData,valueKey }) => {

    const onFormSubmit = async (e) => {


        e.preventDefault()

        const form: any = document.querySelector('.form')
        const formData: any = new FormData(form)
        let val;
        for (const pair of formData.entries()) {
            val = pair[1]
        }
        formData.append('value', val)
        formData.append('key', akey)




        let actionRoute = { resource: `mobile/update/User-privacy-item`, param: null, data: formData, method: 'post' };

        return storeOrUpdate(actionRoute).then(
            (response) => {
                fetchData()
            }
        ).catch((errors) => {
            // setNotify({
            //     isOpen: true,
            //     message: errors.response.data.msg,
            //     type: 'error'
            // })
        })
    };

    return (
        <form className='form' onSubmit={onFormSubmit}>
            <div>
                <FormControl fullWidth>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={valueKey}
                        name="radio-buttons-group"
                    >
                        <Grid container>
                            <Grid marginBottom={3} container xs={11}>
                                <Grid md={2} xs={12}>
                                    <div className='icon-privacy'>
                                        <img src={all} alt="pubic" />
                                    </div>
                                </Grid>
                                <Grid md={10} xs={12}>
                                    <Typography  marginX={1} variant='h6' component={'h2'} fontWeight={'bolder'}>
                                        العامة
                                    </Typography>
                                    <Typography marginX={1} variant='subtitle1' component={'div'} >
                                        متاح لكل أعضاء الموقع
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControlLabel  control={<Radio  name='privacy' value={'all'} className='privacy-checked' />} label="" />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid marginBottom={3} container xs={11}>
                                <Grid md={2} xs={12}>
                                    <div className='icon-privacy'>
                                        <img src={male} alt="pubic" />
                                    </div>
                                </Grid>
                                <Grid md={10} xs={12}>
                                    <Typography marginX={1} variant='h6' component={'h2'} fontWeight={'bolder'}>
                                        الرجال فقط
                                    </Typography>
                                    <Typography marginX={1} variant='subtitle1' component={'div'} >
                                        الأعضاء من الرجال فقط
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControlLabel control={<Radio name='privacy'  value={'male'} className='privacy-checked' />} label="" />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid marginBottom={3} container xs={11}>
                                <Grid md={2} xs={12}>
                                    <div className='icon-privacy'>
                                        <img width={'30px'} src={female} alt="pubic" />
                                    </div>
                                </Grid>
                                <Grid md={10} xs={12}>
                                    <Typography marginX={1} variant='h6' component={'h2'} fontWeight={'bolder'}>
                                        النساء فقط
                                    </Typography>
                                    <Typography marginX={1} variant='subtitle1' component={'div'} >
                                        الأعضاء من النساء فقط
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControlLabel control={<Radio name='privacy'   value={'female'} className='privacy-checked' />} label="" />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid marginBottom={3} container xs={11}>
                                <Grid md={2} xs={12}>
                                    <div className='icon-privacy'>
                                        <img src={only_me} alt="pubic" />
                                    </div>
                                </Grid>
                                <Grid md={10} xs={12}>
                                    <Typography marginX={1} variant='h6' component={'h2'} fontWeight={'bolder'}>
                                        أنا فقط
                                    </Typography>
                                    <Typography marginX={1} variant='subtitle1' component={'div'} >
                                        لن تظهر لأي شخص
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControlLabel control={<Radio name='privacy'   value={'only_me'} className='privacy-checked' />} label="" />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>

                <Grid container justifyContent={'center'}>
                    <Grid item>
                        <Button type='submit' className='btn-popup' variant='contained' autoFocus >
                            اضافة
                        </Button>
                    </Grid>
                </Grid>

            </div>


        </form>
    )
}

export default ProfilePrivacy