import { Button, Grid } from "@mui/material";
import { Container } from "@mui/system";
import about from '../../../assets/images/home/about.png';
import { useNavigate } from "react-router-dom";
const About = ({ item }) => {
    const navigate = useNavigate();
    return (
        <section className="about">
            <h2 className="intro-heading text-center">
                عن عائلة { item.name }
            </h2>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <div>
                            <img src={item.image} alt="tree image1" />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div>
                           
                            <div className="content-description">
                                {item.info}
                            </div>

                            <Button variant="outlined" onClick={() => navigate(`/family/${item.id}`)}>
                                قراءة المزيد
                            </Button>

                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
}

export default About