import { Box, Button, FormControl, FormLabel, Grid, TextField } from "@mui/material"
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import logo from '../../../../assets/images/home/logo.svg';
import AnimatedPage from "../../../../components/Animations/AnimatedPages";
import Preloader from "../../../../components/Preloader";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { storeOrUpdate } from "../../../../plugins/api";
import { useState } from "react";
import PasswordInput from "../../../../components/Forms/PasswordInput";
import Notification from "../../../../components/Notifications/Notification";
const NewPassword = () => {

    const formMethods = useForm<FormData>({
        defaultValues: null,
    });

    const { handleSubmit, reset, setError } = formMethods;

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

    const navigate = useNavigate();

    const onFormSubmit = async (data: FormData) => {

        let fd = new FormData();
        fd.append("email",localStorage.getItem("email"))
        fd.append("reset_code",localStorage.getItem("reset_code"))
        fd.append("password",data['password'])
        fd.append("password_confirmation",data['password_confirmation'])

        let actionRoute = { resource: `auth/forget`, param: null, data: fd, method: 'post' };

        return storeOrUpdate(actionRoute).then(
            (response) => {
                // let user = response.data.data
                // window.localStorage.setItem("user", JSON.stringify(user));
                // window.localStorage.setItem("token", user.token)
                // if (user.user_verifiedFamilyDetails == true) {
                //     navigate('/');
                // } else if (!user.user_verifiedAccount) {
                //     navigate('/auth/verify');
                // } else if (!user.user_filledFamilyDetails) {
                //     navigate('/profile');
                // } else if (user.user_filledFamilyDetails && user.user_verifiedAccount) {
                //     setNotify({
                //         isOpen: true,
                //         message: 'تم استعادة كلمة المرور  يرجى انتظار مواقة الادارة ',
                //         type: 'success'
                //     })
                // }
                setNotify({
                    isOpen: true,
                    message: 'تم تغيير كلمة المرور بنجاح',
                    type: 'success'
                })

                setTimeout(()=>{
                    navigate('/auth/login');
                },1000)
       

            }
        ).catch((errors) => {
            setNotify({
                isOpen: true,
                message: errors.response.data.msg,
                type: 'error'
            })
        })

    };
    return (
        <AnimatedPage>
            <Preloader />
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <section className='auth'>
                <Grid container spacing={1}>

                    <Grid className="auth__right" item xs={12} sm={6}>
                        <FormProvider {...formMethods}>
                            <form onSubmit={handleSubmit(onFormSubmit)}>
                                <div >
                                    <Container>
                                        <div className="text-center mt-2">
                                            <Link to="/" >
                                                <img className='logo' src={logo} alt="logo" />
                                            </Link>
                                        </div>
                                        <h2>
                                            إنشاء كلمة مرور جديدة
                                        </h2>
                                      
                                        <PasswordInput field={'password'} label={'كلمة المرور'}
                                            rules={{
                                                required: true,
                                            }} />

                                        <PasswordInput field={'password_confirmation'} label={' اعادة كلمة المرور '}
                                            rules={{
                                                required: true,
                                            }} />


                                        <Box p={1} mt={2}>
                                            <Grid container lg={12} justifyContent="center">
                                                <Grid item>
                                                    <Button type="submit" variant="contained"  >
                                                        إنشاء
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Container>
                                </div>
                            </form>
                        </FormProvider>
                    </Grid>

                    <Grid className="auth__left" item xs={12} sm={6}>

                    </Grid>
                </Grid>
            </section>
        </AnimatedPage>
    )
}

export default NewPassword