import { Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Intro from '../../../components/Intro'
import Item from '../../../components/Blog'
import header from '../../../assets/images/gallery/header.png';
import gallery1 from '../../../assets/images/home/gallery1.png';
import gallery2 from '../../../assets/images/home/gallery2.png';
import gallery3 from '../../../assets/images/home/gallery3.png';
import AnimatedPage from '../../../components/Animations/AnimatedPages';
import DialogAdd from '../../../components/Dialog/Add';
import AddGallery from '../../../components/FormsWebsite/Gallery/AddGallery';
import Button from '../../../components/Controls/Button';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { indexData } from '../../../plugins/api';
import DialogFire from '../../../components/Dialog/Fire';
import DialogSuccess from '../../../components/Dialog/Success';

const GalleryDetails = () => {
    const [confirmDialogSuccess, setConfirmDialogSuccess] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialogSuccess = () => {
        setConfirmDialogSuccess({
            isOpen: true,
            title: 'تم إرسال الطلب للإدارة بنجاح',
            subTitle: 'سوف يتم مراجعة بيانات ملاحظاتك مع الإدارة وإرسال الرد لك',
            onConfirm: () => { }
        })
    }


    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = () => {
        setConfirmDialog({
            isOpen: true,
            title: ' إضافة عرض جديد',
            subTitle: '',
            onConfirm: () => { }
        })
    }

    const [item, setItem] = useState(null)
    const [popup, setPopup] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openPopup = (item) => {
        setItem(item)
        setPopup({
            isOpen: true,
            title: '',
            subTitle: '',
            onConfirm: () => { }
        })
    }

    const { id } = useParams()
    const [items, setItems] = useState(null)

    const getItems = async () => {
        await indexData({ resource: `mobile/media-info?album_id=${id}` }).then((res) => {
            const items = res.data.data.items;
            setItems(items);
        });
    };
    useEffect(() => {
        getItems();
    }, []);

    const fetchData = () => {
        getItems()
        setConfirmDialog({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })
        openDialogSuccess()
    };



    return (
        <AnimatedPage>
            {
                item &&

                <DialogFire btnName="اغلاق" confirmDialog={popup} setConfirmDialog={setPopup}>

                    {
                        item.media_type == 'image' && <img className='g-img' src={item?.media} alt={item?.media_title} />
                    }

                    {
                        item.media_type == 'video' &&
                        <iframe className='iframe-video' src={`https://www.youtube.com/embed/${item?.youtube_id}`} title="YouTube video player"

                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                    }


                </DialogFire>
            }

            <DialogSuccess confirmDialog={confirmDialogSuccess} setConfirmDialog={setConfirmDialogSuccess}></DialogSuccess>
            <section className='gallery page'>
                <Intro image={header} />
                <div className='page__content '>
                    <Container>
                        <Grid xs container direction="row-reverse" spacing={2}
                            alignItems="center">
                            <Grid item xs={12} sm={4} className='text-left px-0' >

                                <Button text={'اضافة محتوى'} onClick={openDialog}>
                                    <Typography className='icon-btn' fontWeight={'bolder'} marginX={1} >+</Typography>
                                </Button>

                                <DialogFire btnName="اضافة" confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}>
                                    <AddGallery fetchData={fetchData}></AddGallery>
                                </DialogFire>
                            </Grid>
                            {/* <Grid item xs={12} sm={4}>
                                <Typography variant='h5' component='h2' className='text-center font-bolder'>
                                    ألبوم العمل
                                </Typography>
                            </Grid> */}
                        </Grid>
                        <div className='gallery__cards'>
                            {

                                items &&
                                <Grid container spacing={5}>
                                    {items.map((item: any) => (
                                        <Grid item xs={12} sm={4}  onClick={() => {
                                            openPopup(item)
                                        }}>
                                            <Item page={''} description={item.description} image={item.media} hint={item.created_at} title={item.media_title} id={'#'} />
                                        </Grid>
                                    ))}

                                </Grid>
                            }
                        </div>

                    </Container>
                </div>

            </section>
        </AnimatedPage>
    )
}

export default GalleryDetails