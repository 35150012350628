import { Box, Button, FormControl, FormLabel, Grid, IconButton, InputAdornment, RadioGroup, FormControlLabel, Radio, InputLabel, OutlinedInput } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { storeOrUpdate } from "../../../plugins/api";
import { Link, useNavigate } from "react-router-dom";
import Notification from "../../Notifications/Notification";
import { useTranslation } from "react-i18next";
import TextInputX from "../../Forms/TextInputX";
import PasswordInput from "../../Forms/PasswordInput";
import RadioInput from "../../Forms/RadioInput";
import CustomizedSwitches from "../../Forms/Switch";
import UploadImage from "../../Forms/UploadImage";
import DialogSimple from "../../Dialog/Simple";
import DialogFire from "../../Dialog/Fire";
import avatar from '../../../assets/images/icons/avatar.svg';

interface FormData {
    email: string,
    password: string
}

const FormRegister = () => {

    const { t } = useTranslation();

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

    const formMethods = useForm<FormData>({
        defaultValues: null,
    });


    const { handleSubmit, reset, setError } = formMethods;

    const navigate = useNavigate();

    const onFormSubmit = async (data: FormData) => {


        let fd = new FormData();
        const keys = Object.keys(data);

        keys.forEach((key) => {

            if (key != 'image') {

                fd.append(key, data[key])
            }
            if (key == 'image') {
                if (data[key].length != 0)
                    fd.append(key, data[key][0])
            }
        });

        if (data['image_show']) {
            fd.append('image_show', '1')
        } else {
            fd.append('image_show', '0')
        }

        let actionRoute = { resource: `/auth/register`, param: null, data: fd, method: 'post' };

        return storeOrUpdate(actionRoute).then(
            (response) => {
                let user = response.data.data
                window.localStorage.setItem("user", JSON.stringify(user));
                setTimeout(() => navigate('/auth/verify'), 10)
                openDialog()

            }
        ).catch((errors) => {


            const allErrors = errors.response.data.msg
            const keys = Object.keys(allErrors);
            keys.forEach((key: any) => {
                setError(key, { type: allErrors[key] }, { shouldFocus: true });
            });
            // setNotify({
            //     isOpen: true,
            //     message: errors.response.data.msg,
            //     type: 'error'
            // })
        })

    };


    const [verifyPage, setVerifyPage] = React.useState('phone');

    const handleClickVerifyPage = (event) => {
        setVerifyPage(event.target.value);
    }

    const goRoute = () => {
        navigate(`/auth/verify/${verifyPage}`)
    }

    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = () => {
        setConfirmDialog({
            isOpen: false,
            title: '',
            subTitle: '',
            onConfirm: () => { alert("hi") }
        })
    }



    return (
        <Fragment>
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onFormSubmit)}>


                    <UploadImage rules={{}} field={'image'} label={''} />

                    <div className="text-center">
                        <CustomizedSwitches changeAction={null} defaultChecked={true} rules={{}} field={'image_show'} label={'اظهار الصورة الشخصية'}></CustomizedSwitches>
                    </div>
                    <TextInputX field={'first_name'} label={'اسم المستخدم'}
                        rules={{
                            required: true,
                        }} />

                    <TextInputX field={'second_name'} label={'اسم الأب'}
                        rules={{
                            required: true,
                        }} />
                    <TextInputX field={'third_name'} label={'اسم الجد'}
                        rules={{
                            required: true,
                        }} />

                    <TextInputX field={'national_no_id'} label={'رقم الهوية'}
                        rules={{
                            required: true,
                        }} />

                    <TextInputX field={'email'} label={'البريد الألكترونى'}
                        rules={{
                            required: true,
                        }} />


                    <TextInputX field={'phone'} label={'رقم الجوال'}
                        rules={{
                            required: true,
                        }} />


                    <PasswordInput field={'password'} label={'كلمة المرور'}
                        rules={{
                            required: true,
                        }} />

                    <PasswordInput field={'password_confirmation'} label={'تأكيد كلمة المرور'}
                        rules={{
                            required: true,
                        }} />
                    <RadioInput defaultValue={'M'} values={[{ label: 'ذكر', value: 'M' }, { label: 'أنثى', value: 'F' }]} field={'gender'} label={'الجنس'}
                        rules={{
                        }} />

                    <RadioInput defaultValue={1} values={[{ label: 'نعم', value: 1 }, { label: 'لا', value: 0 }]} field={'parent_azhar'} label={'هل الأب من عائلة الأزهر'}
                        rules={{
                        }} />


                    <RadioInput defaultValue={1} values={[{ label: 'نعم', value: 1 }, { label: 'لا', value: 0 }]} field={'mother_azhar'} label={'هل الأم من عائلة الأزهر'}
                        rules={{
                        }} />

                    <div hidden>
                        <TextInputX defaultValue={'chrome'} field={'device_name'} label={''}
                            rules={{}} />
                    </div>
                    <div >
                        <DialogFire btnName="إرسال" confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}>
                            <RadioGroup
                                onChange={handleClickVerifyPage}
                                value={verifyPage}
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="email"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="phone" control={<Radio />} label="رقم الجوال" />
                                <FormControlLabel value="email" control={<Radio />} label="البريد الألكترونى" />
                            </RadioGroup>
                        </DialogFire>
                    </div>



                    <Box p={1} mt={2}>
                        <Grid container lg={12} justifyContent="center">
                            <Grid item  >
                                <Button type="submit" variant="contained" >
                                    إنشاء
                                </Button>
                                <div className="text-center">
                                    <Link className="auth__link font-bolder" to="/auth/login" >
                                        <span>
                                            لديك حساب بالفعل ؟
                                        </span>
                                        <span>
                                            قم بتسجيل الدخول
                                        </span>
                                    </Link>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </FormProvider>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </Fragment>
    );


}
export default FormRegister;