import Intro from '../../components/Intro'
import family from '../../assets/images/familyDetails/family.png';
import header from '../../assets/images/familyDetails/header.png';
import { Container } from '@mui/system';
import AnimatedPage from '../../components/Animations/AnimatedPages';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { showData } from '../../plugins/api';
const Family = () => {


    const { id } = useParams()
    const [item, setItem] = useState(null)

    const getItem = async () => {
        await showData({ resource: `mobile/get-family`, param: id }).then((res) => {
            const item = res.data.data;
            setItem(item);
        });
    };

    useEffect(() => {
        getItem();
    }, []);


    return (
        <AnimatedPage>
            {
                item &&

                <section className='family'>

                    <Intro image={item.image} />

                    <div className='content'>
                        <Container>
                            <h2>
                                {item.name}
                            </h2>
                            <div>
                                {item.info}
                            </div>
                            <img src={item.image} alt="family" />

                        </Container>
                    </div>
                </section>
            }
        </AnimatedPage>
    )
}

export default Family