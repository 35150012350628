import { Box, Button, FormControl, FormLabel, Grid, TextField, Typography } from "@mui/material"
import { Container } from "@mui/system";
import logo from '../../../../assets/images/home/logo.svg';
import AnimatedPage from "../../../../components/Animations/AnimatedPages";
import OTPInput, { ResendOTP } from "otp-input-react";
import Form from "../../../../components/Login/Form";
import { useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Preloader from "../../../../components/Preloader";
import { useNavigate } from "react-router-dom";
import { storeOrUpdate } from "../../../../plugins/api";
import Notification from "../../../../components/Notifications/Notification";

const ChooseVerifyOtp = () => {
    const [OTP, setOTP] = useState("------");
    const navigate = useNavigate();
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const getUser = JSON.parse(window.localStorage.getItem("user"));


    const [searchParams, setSearchParams] = useSearchParams();
    const isReset = searchParams.get("reset")



    const { id } = useParams()

    const sendOtp = () => {

        let fd = new FormData();

        fd.append("verification_code", OTP)

        let actionRoute = { resource: `auth/activate-email`, param: null, data: fd, method: 'post' };

        return storeOrUpdate(actionRoute).then(
            (response) => {
                if (isReset) {
                    navigate(`/auth/new-password`)
        
                }else{
                    navigate(`/profile`)
                }

            }
        ).catch((errors) => {
            setNotify({
                isOpen: true,
                message: errors.response.data.msg,
                type: 'error'
            })
        })
    }

    const reSendOtp = () => {

        let fd = new FormData();

        fd.append("email", getUser.member.email)

        let actionRoute = { resource: `auth/resend_code`, param: null, data: fd, method: 'post' };

        return storeOrUpdate(actionRoute).then(
            (response) => {
                setNotify({
                    isOpen: true,
                    message: 'تم  اعادة ارسال الكود بنجاح',
                    type: 'success'
                })
            }
        ).catch((errors) => {
            setNotify({
                isOpen: true,
                message: errors.response.data.msg,
                type: 'error'
            })
        })
    }
    return (
        <AnimatedPage>
            <Preloader />
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <section className='auth'>
                <Grid container spacing={1}>
                    <Grid className="auth__right" item xs={12} sm={6}>
                        <div >
                            <Container>
                                <div className="text-center mt-3">
                                    <Link to="/" >
                                        <img className='logo' src={logo} alt="logo" />
                                    </Link>
                                </div>
                                <h2 className="mt-1 mb-0">
                                    كود التفعيل
                                </h2>
                                <Typography mt={1} mb={3} variant="body1" component={'div'}>
                                    برجاء إدخال كود التفعيل
                                </Typography>

                                <Grid container justifyContent={'center'}>
                                    <Grid item>
                                        <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} />
                                        <ResendOTP onResendClick={() => console.log("Resend clicked")} />
                                    </Grid>
                                </Grid>
                                <Typography className="pointer" onClick={() => reSendOtp()} mt={4} textAlign={'center'} variant="body1" component={'div'}>
                                    إعادة إرسال الكود
                                </Typography>

                                <Box p={1} >
                                    <Grid container lg={12} justifyContent="center">
                                        <Grid item>
                                            <Button type="submit" variant="contained" onClick={() => sendOtp()}>
                                                متابعة
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Container>
                        </div>
                    </Grid>
                    <Grid className="auth__left" item xs={12} sm={6}>
                    </Grid>
                </Grid>
            </section>
        </AnimatedPage>
    )
}

export default ChooseVerifyOtp