import { Box, Button, FormControl, FormLabel, Grid, TextField } from "@mui/material"
import { Container } from "@mui/system";
import { Link, useParams } from "react-router-dom";
import logo from '../../../../assets/images/home/logo.svg';
import AnimatedPage from "../../../../components/Animations/AnimatedPages";
import Form from "../../../../components/Login/Form";
import Preloader from "../../../../components/Preloader";
import { useNavigate } from "react-router-dom";
import { storeOrUpdate } from "../../../../plugins/api";
import Notification from "../../../../components/Notifications/Notification";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import TextInputX from "../../../../components/Forms/TextInputX";

const ResetPasswordType = () => {

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

    const formMethods = useForm<FormData>({
        defaultValues: null,
    });
    const { handleSubmit, reset, setError } = formMethods;
    const navigate = useNavigate();
    const { type } = useParams();
    const label = type == 'email' ? 'البريد الألكترونى' : 'رقم الجوال';
    const onFormSubmit = async (data: FormData) => {
        let fd = new FormData();
        fd.append("method", type)
        fd.append("login", data['login'])
        let actionRoute = { resource: `/auth/forget`, param: null, data: fd, method: 'post' };

        return storeOrUpdate(actionRoute).then(
            (response) => {
                navigate(`/auth/reset-password/${type}/${data['login']}`)
            }
        ).catch((errors) => {
            setNotify({
                isOpen: true,
                message: errors.response.data.msg,
                type: 'error'
            })
        })
    }


    return (
        <AnimatedPage>
            <Preloader />
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <section className='auth'>
                <Grid container spacing={1}>
                    <Grid className="auth__right" item xs={12} sm={6}>
                        <FormProvider {...formMethods}>
                            <form onSubmit={handleSubmit(onFormSubmit)}>

                                <div >
                                    <Container>
                                        <div className="text-center mt-3">
                                            <Link to="/" >
                                                <img className='logo' src={logo} alt="logo" />
                                            </Link>
                                        </div>
                                        <h2 className="mt-1">
                                            استعادة كلمة المرور
                                        </h2>

                                        <TextInputX field={'login'} label={label}
                                            rules={{
                                                required: true,
                                            }} />

                                        <Box p={1} mt={5}>
                                            <Grid container lg={12} justifyContent="center">
                                                <Grid item>
                                                    <Button type="submit" variant="contained" >
                                                        إرسال
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Container>
                                </div>
                            </form>
                        </FormProvider>
                    </Grid>
                    <Grid className="auth__left" item xs={12} sm={6}>
                    </Grid>
                </Grid>
            </section>
        </AnimatedPage>
    )
}

export default ResetPasswordType