import { Autocomplete, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import dummyData from '../../../../plugins/data/data';
import UploadFile from '../../../Forms/UploadFile';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { storeOrUpdate } from '../../../../plugins/api';

import TextInputX from '../../../Forms/TextInputX';
import Button from '../../../Controls/Button';
import Notification from '../../../Notifications/Notification';
import DateInput from '../../../Forms/DateInput';
import { useParams } from 'react-router-dom';
const AddGallery = ({ fetchData }) => {
    const defaultValues = null;
    const { id } = useParams()
    const [value, setValue] = useState<Dayjs | null>(null);
    const [mediaType, setMediaType] = useState('image');
    const formMethods = useForm<FormData>({
        defaultValues: defaultValues,
    });
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const { handleSubmit, setError } = formMethods;


    const onFormSubmit = async (data: any) => {

        let fd = new FormData();
        const keys = Object.keys(data);
        console.log(data)
        keys.forEach((key) => {
            fd.append(key, data[key])
            if (key == 'media') {
                fd.append(key, data[key][0])
            }
            if (key == 'media_video') {
                fd.append('media', data[key])
            }
            
        });
        fd.append('media_type', mediaType )


        let actionRoute = { resource: `mobile/media-info?album_id=${id}`, param: null, data: fd, method: 'post' };
        if (defaultValues) {
            actionRoute = { resource: `mobile/media-info?album_id=${id}`, param: defaultValues.id, data: data, method: 'put' };
        }
        return storeOrUpdate(actionRoute).then(
            (response) => {
                fetchData()
            }
        ).catch((errors) => {
            setNotify({
                isOpen: true,
                message: errors.response.data.msg,
                type: 'error'
            })
        })

    };

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onFormSubmit)}>

                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                        <Typography className='font-bolder' variant='h6' component='h3'>

                        </Typography>
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={mediaType}
                        name="media_type"
                    >

                        <FormControlLabel  onChange={() => {
                            setMediaType('image')
                        }} value={'image'} control={<Radio />} label={'صورة'} />
                        <FormControlLabel onChange={() => {
                            setMediaType('video')
                        }} value={'video'} control={<Radio />} label={'فيديو'} />

                    </RadioGroup>
                </FormControl>

                {
                    mediaType == 'image' &&
                    <UploadFile accept={'image/*'} title={'الصورة'} rules={{
                        required: true
                    }} field={'media'} label={'يرجى إرفاق الصورة التي تريدها'} />
                }
                {
                    mediaType == 'video' &&
                    <TextInputX field={'media_video'} label={'رابط اليوتيوب'}
                        rules={{
                            required: true
                        }} />

                }
                <TextInputX field={'media_title'} label={'العنوان'}
                    rules={{
                        required: true, 
                    }} />
                <div  hidden>


                    <TextInputX  value={id} field={'media_id'} label={''}
                        rules={{}} />

                  

                </div>
                <Grid container justifyContent={'center'}>
                    <Grid item>
                        <Button type='submit' className='btn-popup' variant='contained' autoFocus >
                            اضافة
                        </Button>
                    </Grid>
                </Grid>

                <Notification
                    notify={notify}
                    setNotify={setNotify}
                />
            </form>
        </FormProvider >

    )
}

export default AddGallery