import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import DialogSuccess from '../Success';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export default function DialogFire({ btnName, confirmDialog, setConfirmDialog, children }) {

    return (
        <div>

            <BootstrapDialog
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                aria-labelledby="customized-dialog-title"
                open={confirmDialog.isOpen}
            >

                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}>
                    <Typography marginTop={5} fontWeight={'bolder'} variant='h5' component={'h2'}>
                        <div className='dialog-content'>
                            {confirmDialog.title}
                        </div>
                    </Typography>
                </BootstrapDialogTitle>
                <DialogContent>
                    <div className='dialog-content'>
                        {children}
                    </div>
                </DialogContent>


                {/* <DialogActions>
                    <Grid container justifyContent={'center'}>
                        <Grid item marginBottom={2}>
                            <Button className='btn-popup' variant='contained'  onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}>
                               {btnName}
                            </Button>
                        </Grid>
                    </Grid>

                </DialogActions> */}
            </BootstrapDialog>

        </div>
    );
}