import { Box, Grid } from "@mui/material";
import { Container } from "@mui/system";
import AppImage from '../../../assets/images/home/app.png';

const MobileApp = () => {
    return (
        <section className="mobile-app">
            <h2 className="intro-heading text-center">
                عن التطبيق
            </h2>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <div className="move-bounce">
                            <img className="img-app" src={AppImage} alt="mobile app" />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div>
                            <h3>
                                تطبيق شجرة أسر آل أزهر
                                <br />
                                السعودية
                            </h3>
                            <Box mb={15}>


                                <div className="content-description" style={{ color: '#7C7C7C' }}>
                                    هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج العميل فى كثير من الأحيان أن يطلع
                                </div>
                            </Box>

                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
}

export default MobileApp