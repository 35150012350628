
import { FormControl, FormLabel, Grid, TextField } from "@mui/material";
import { ChangeEvent, useRef, useState } from 'react';
import { useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import avatar from '../../../assets/images/icons/avatar.svg';
import camera from '../../../assets/images/icons/camera.svg';

import fileImage from "../../../assets/images/icons/file.svg"
const UploadImage = ({ label, field, rules, defaultIamge=null }) => {

    const { t } = useTranslation();
    const inputElement = useRef();
    const { register, formState: { errors } } = useFormContext();
    const [file, setFile] = useState<File>();
    let image= defaultIamge ? defaultIamge : avatar;
    const [img, setImg] = useState(image);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        let file = e.target.files[0]
        let src = URL.createObjectURL(file);
        console.log(src)
        setImg(src);
        if (file) {
            setFile(file);
        }

    };

    const handleUploadClick = () => {
        if (!file) {
            return;
        }
    }


    return (
        <div>

            <div className="file-upload unset-img">
                <FormControl fullWidth variant="outlined" >

                    <input  {...register(field, rules)} accept="image/*" type="file" onChange={handleFileChange} />
                    <Grid container justifyContent={'center'} alignItems={'center'} spacing={'2'}>
                        <Grid item >
                            <div className="user-image text-center">
                                <img  className='user-image__avatar' src={img} alt="logo" />
                                <img className='user-image__camera' src={camera} />
                            </div>
                        </Grid>

                    </Grid>
                    <div>
                        {errors[field] &&
                            <span style={{ color: "red" }}>
                                {t(errors[field].type)}
                            </span>
                        }
                    </div>
                </FormControl>
            </div>
        </div>
    )
}

export default UploadImage