import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import '../../plugins/i18n/index'
import Preloader from '../Preloader';
import AppFooter from './Footer';
import AppHeader from './Header';


export default function Layout() {
    const getUser = JSON.parse(window.localStorage.getItem("user"));
    return (
        <Fragment>

            {getUser?.user_verifiedFamilyDetails &&
                <AppHeader />
            }
            {getUser?.user_filledFamilyDetails && !getUser?.user_verifiedFamilyDetails &&
                <div className='info-user color-success'>
                    <img width={100} src="https://cdn.dribbble.com/users/11609495/screenshots/18251844/media/a4d3556d8b51796968cbcc63ea7c5abc.gif" alt="" />
                    تم اكتمال بياناتك  بنجاح وبانتظار موافقة الادارة...
                </div>
            }
            {!getUser?.user_filledFamilyDetails && !getUser?.user_verifiedFamilyDetails &&
                <div className='info-user color-grey'>
                    من فضلك قم باختيار الأب والأم لاكمال بياناتك ...
                </div>
            }

            <Outlet />
            {getUser?.user_verifiedFamilyDetails &&
                <AppFooter />
            }
        </Fragment>
    );
}
