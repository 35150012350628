import { Button, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Intro from '../../components/Intro'
import Item from '../../components/Blog'
import header from '../../assets/images/requests/header.png';
import Action from '../../components/Action';
import AnimatedPage from '../../components/Animations/AnimatedPages';
import DialogAdd from '../../components/Dialog/Add';
import AddAdvance from '../../components/FormsWebsite/Requests/AddAdvance';
import AddEvent from '../../components/FormsWebsite/Requests/AddEvent';
import RequestAdvance from '../../components/Requests/Advance';
import RequestEvent from '../../components/Requests/Event';
import { useEffect, useState } from 'react';
import { indexData } from '../../plugins/api';



const Requests = () => {


    const [requests, setRequests] = useState([]);
    const getRequests = () => {
        indexData({ resource: `mobile/request-types` }).then((res) => {
            const requests = res.data.data.items
            setRequests(requests);
        });
    };

    useEffect(() => {
        getRequests()
    }, []);


    return (
        <AnimatedPage>
            <section className='requests page'>
                <Intro image={header} />
                <div className='page__content text-center mt-3'>
                    <Container>
                        <Grid container spacing={8} justifyContent='center'>
                            {requests.map((request: any) => (
                                <RequestEvent id={request.id} title={request.title} description={request.description} image={request.image}></RequestEvent>
                            ))}
                        </Grid>
                    </Container>
                </div>
            </section>
        </AnimatedPage >
    )
}

export default Requests