import { Box, Button, FormControl, FormLabel, Grid, IconButton, InputAdornment, RadioGroup, FormControlLabel, Radio, InputLabel, OutlinedInput } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { storeOrUpdate } from "../../../plugins/api";
import { Link, useNavigate } from "react-router-dom";
import Notification from "../../Notifications/Notification";
import { useTranslation } from "react-i18next";
import TextInputX from "../../Forms/TextInputX";
import PasswordInput from "../../Forms/PasswordInput";
import DialogSimple from "../../Dialog/Simple";


interface FormData {
    email: string,
    password: string
}

const FormLogin = () => {

    const { t } = useTranslation();

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

    const formMethods = useForm<FormData>({
        defaultValues: null,
    });

    const { handleSubmit, reset, setError } = formMethods;

    const navigate = useNavigate();

    const onFormSubmit = async (data: FormData) => {
        let actionRoute = { resource: `auth/login`, param: null, data: data, method: 'post' };

        return storeOrUpdate(actionRoute).then(
            (response) => {
                let user = response.data.data
                window.localStorage.setItem("user", JSON.stringify(user));
                window.localStorage.setItem("token", user.token)
                if (user.user_verifiedFamilyDetails == true) {
                    navigate('/');
                } else if (!user.user_verifiedAccount) {
                    navigate('/auth/verify');
                } else if (!user.user_filledFamilyDetails) {
                    navigate('/profile');
                } else if (user.user_filledFamilyDetails && user.user_verifiedAccount) {
                    setNotify({
                        isOpen: true,
                        message: 'تم اكتمال بياناتك بنجاح وجارى انتظار موافقة الادارة ',
                        type: 'success'
                    })
                    navigate('/profile');
                }

            }
        ).catch((errors) => {
            setNotify({
                isOpen: true,
                message: errors.response.data.msg,
                type: 'error'
            })
        })

    };


    const [resetPage, setResetPage] = React.useState('phone');

    const handleClickResetPage = (event) => {
        setResetPage(event.target.value);
    }

    const goRoute = () => {
        navigate(`/auth/reset-password/${resetPage}`)
    }

    return (
        <Fragment>
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onFormSubmit)}>

                    <TextInputX field={'phone'} label={'البريد الالكتروني / رقم الجوال'}
                        rules={{
                            required: true,
                        }} />


                    <PasswordInput field={'password'} label={'كلمة المرور'}
                        rules={{
                            required: true,
                        }} />

                    <div hidden>
                        <TextInputX defaultValue={'chrome'} field={'device_name'} label={''}
                            rules={{}} />
                    </div>
                    <div >
                        <DialogSimple goRoute={goRoute} title={'اختيار طريقة استعادة كلمة المرور'}>
                            <RadioGroup
                                onChange={handleClickResetPage}
                                value={resetPage}
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="phone" control={<Radio />} label="رقم الجوال" />
                                <FormControlLabel value="email" control={<Radio />} label="البريد الألكترونى" />
                            </RadioGroup>
                        </DialogSimple>
                    </div>

                    <Box p={1} mt={2}>
                        <Grid container lg={12} justifyContent="center">
                            <Grid item >
                                <Button type="submit" variant="contained" >
                                    تسجيل الدخول
                                </Button>
                                <div >
                                    <Link className="auth__link font-bolder" to="/auth/register" >
                                        <span>
                                            ليس لديك حساب؟
                                        </span>
                                        <span>
                                            إنشاء حساب
                                        </span>
                                    </Link>
                                </div>

                            </Grid>
                        </Grid>
                    </Box>

                </form>
            </FormProvider>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </Fragment>
    );


}
export default FormLogin;