import Grid from '@mui/material/Grid'
import React from 'react'
import Action from '../../Action'
import DialogAdd from '../../Dialog/Add'

import eventImage from '../../../assets/images/requests/event.svg';
import { useState } from 'react';
import DialogSuccess from '../../Dialog/Success';
import AddEvent from '../../FormsWebsite/Requests/AddEvent';
import DialogFire from '../../Dialog/Fire';
import Button from '../../Controls/Button';
const RequestEvent = ({ id, title, description, image }) => {
    const [confirmDialogSuccess, setConfirmDialogSuccess] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialogSuccess = () => {
        setConfirmDialogSuccess({
            isOpen: true,
            title: 'تم إرسال التبرع  للإدارة بنجاح',
            subTitle: 'سوف يتم مراجعة بيانات ملاحظاتك مع الإدارة وإرسال الرد لك',
            onConfirm: () => { }
        })
    }

    const fetchData = () => {

        setConfirmDialog({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })
        openDialogSuccess()
    };


    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = () => {
        setConfirmDialog({
            isOpen: true,
            title: ` تقديم طلب ${title}`,
            subTitle: '',
            onConfirm: () => { alert("hi") }
        })
    }
    return (


        <Grid item xs={12} sm={6}>
            <DialogSuccess confirmDialog={confirmDialogSuccess} setConfirmDialog={setConfirmDialogSuccess}></DialogSuccess>
            <Action
                page={`/requests/${id}`}
                image={image}
                title={title}
                description={description}
                btns={true} >
                <Button variant='contained' onClick={() => {
                    openDialog()
                }}>
                    تقديم الطلب
                </Button>
                <DialogFire btnName="تأكيد التبرع" confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}>
                    <AddEvent id={id} fetchData={fetchData} ></AddEvent>
                </DialogFire>

            </Action>

        </Grid>

    )
}

export default RequestEvent