import { Button, Grid } from '@mui/material'
import { Container } from '@mui/system'

import Intro from '../../components/Intro'
import header from '../../assets/images/gallery/header.png';
import Item from '../../components/Home/Gallery/Item'
import gallery1 from '../../assets/images/home/gallery1.png';
import gallery2 from '../../assets/images/home/gallery2.png';
import gallery3 from '../../assets/images/home/gallery3.png';
import AnimatedPage from '../../components/Animations/AnimatedPages';
import AddAdGallery from '../../components/FormsWebsite/Gallery/AddGallery';
import DialogAdd from '../../components/Dialog/Add';
import DialogSuccess from '../../components/Dialog/Success';
import { indexData } from '../../plugins/api';
import { Fragment, useEffect, useState } from "react"

const Gallery = () => {

    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = () => {
        setConfirmDialog({
            isOpen: true,
            title: 'تمت إضافة الصور بنجاح',
            subTitle: 'سوف يتم مراجعة البيانات  مع الإدارة وإرسال الرد لك',
            onConfirm: () => { alert("hi") }
        })
    }

    const [galleries, setGalleries] = useState([]);
    const getGalleries = () => {
        indexData({ resource: `mobile/media` }).then((res) => {
            const galleries = res.data.data.items
            setGalleries(galleries);
        });
    };

    useEffect(() => {
        getGalleries()
    }, []);


    return (
        <AnimatedPage>
            <section className='gallery page'>
                <Intro image={header} />
                <div className='page__content'>
                    <Container>

                        <div className='gallery__cards'>
                            {
                                galleries &&

                                <Grid container spacing={3}>
                                    {galleries.map((item: any) => (
                                        <Grid item xs={12} sm={4}>
                                            <Item image={item.image} title={item.title} id={item.id} />
                                        </Grid>
                                    ))}
                                </Grid>
                            }
                        </div>


                    </Container>
                </div>
                <DialogSuccess confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}></DialogSuccess>
            </section>
        </AnimatedPage>
    )
}

export default Gallery