import { useFormContext } from "react-hook-form";
import TextField from '@mui/material/TextField';

import { FormControl, FormLabel } from "@mui/material";


const TextInput = ({ field, label, rules, showLabel=true, ...other  }) => {



  return (
    <div>
      <FormLabel>
        {label}
      </FormLabel>
      <FormControl fullWidth variant="outlined" >
        <TextField
          {...other}

          fullWidth

          label={label + '...'}


        />
      </FormControl>
    </div>

  );
};

export default TextInput;
