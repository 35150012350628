import Routing from "./routes/Routing";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Provider } from "react-redux";
import { store } from "./plugins/store";
import { useEffect, useState } from "react";
import { indexData, showData } from "./plugins/api";
import { json } from "stream/consumers";
import { stringify } from "querystring";
// import { jssPreset } from '@mui/material/styles';
// import { create } from 'jss';
// import rtl from 'jss-rtl';


// Configure JSS
// const jss = create({
//   plugins: [...jssPreset().plugins, rtl()],
// });


const theme = createTheme({
  direction: 'rtl',
  palette: {
    primary: {
      light: '#757ce8',
      main: '#9F7D4F',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#302517',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});



function App() {


  const [user, setUser] = useState(true)

  const getUser = async () => {
    await indexData({ resource: `profile` }).then((res) => {
      const user = res.data.data;
      setUser(user);
      window.localStorage.setItem("user", JSON.stringify(user));
    });
  };

  useEffect(() => {
    getUser();
  }, []);



  return (
    <Provider store={store}>
      {
        user &&

        <ThemeProvider theme={theme}>
          <div className="App">
            <Routing></Routing>
          </div>
        </ThemeProvider>
      }
    </Provider>
  );
}

export default App;
