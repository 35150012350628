
import Intro from '../../../components/Intro'
import header from '../../../assets/images/requests/header.png';
import requestImage from '../../../assets/images/icons/request.png';
import { Container } from '@mui/system';
import { ButtonBase, Grid, Paper, Typography } from '@mui/material';
import AnimatedPage from '../../../components/Animations/AnimatedPages';
import DialogDelete from '../../../components/Dialog/Delete';

import { useState, useEffect } from 'react';
import DialogFire from '../../../components/Dialog/Fire';
import EditRequest from '../../../components/FormsWebsite/Requests/Edit';
import { useParams } from 'react-router-dom';
import { indexData, showData } from '../../../plugins/api';

const RequestPrevious = () => {
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = () => {
        setConfirmDialog({
            isOpen: false,
            title: '',
            subTitle: '',
            onConfirm: () => { alert("hi") }
        })
    }


    const { id } = useParams()
    const [items, setItems] = useState(null)

    const getItems = async () => {
        await indexData({ resource: `mobile/get-request?type=${id}` }).then((res) => {
            const items = res.data.data.items;
            setItems(items);
        });
    };

    useEffect(() => {
        getItems();
    }, []);



    return (
        <AnimatedPage>
            <DialogFire btnName="إرسال" confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}>
                <EditRequest></EditRequest>
            </DialogFire>

            <section className='requests events page'>
                <Intro image={header} />
                <div className='events__content page__content  mt-3'>
                    <Container>
                        <Grid item xs container justifyContent='center' spacing={2}>
                            <Typography className='font-bolder' gutterBottom variant="h5" component="h2">
                                الطلبات السابقة
                            </Typography>
                        </Grid>

                        {items && items.map((item: any) => (
                            <div className='events__content__card'>

                                <Paper className='paper'

                                    sx={{
                                        p: 1,
                                        margin: 'auto',
                                        flexGrow: 1,
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase onClick={() => openDialog()} sx={{ width: 128, height: 128 }}>
                                                <img  src={item.files} alt="image request" className='events__content__card__media img-request' />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs={10} container direction="column" spacing={2}>
                                                <Grid className='pointer' item xs onClick={() => openDialog()}>
                                                    <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                        {item.reason_description}
                                                    </Typography>
                                                    <Typography className='text-name' variant="body2" gutterBottom>
                                                        {item.more_info}
                                                    </Typography>

                                                </Grid>

                                            </Grid>
                                            <Grid item className='notify' md={2}>
                                                <Typography variant="subtitle1" className=' font-bolder' component="div">
                                                    <div className='action-btn text-danger remove-btn'>
                                                        <DialogDelete getItems={getItems} id={item.id} title={`${item.reason_description} هل أنت متأكد من حذف الطلب `}></DialogDelete>
                                                    </div>
                                                    <br />

                                                    <span className='status text-primary'>
                                                        {item.status}
                                                    </span>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                        ))}


                    </Container>
                </div>
            </section>
        </AnimatedPage>
    )
}

export default RequestPrevious