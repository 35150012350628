import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
    baseUrl: 'http://localhost:8000/api/',
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        // const token = getState().auth.token
        // if (token) {
        //     headers.set("authorization", `Bearer ${token}`)
        // }
        headers.set("Authorization", 'Bearer 106|mzQEy48Dii2MWSqyq0UjKyzce2IRmS4fwIXcF2Bv')

        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    return result
}

export const baseApi = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
})