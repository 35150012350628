import { Button, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Intro from '../../components/Intro'
import Item from '../../components/Blog'
import { useEffect, useState } from 'react';
import header from '../../assets/images/opinions/header.png';
import opinion from '../../assets/images/opinions/opinion.svg';
import vote from '../../assets/images/opinions/vote.svg';

import Action from '../../components/DonationAction';
import AnimatedPage from '../../components/Animations/AnimatedPages';
import { indexData } from '../../plugins/api';
const Opinions = () => {



    const [votes, setVotes] = useState([]);
    const getVotes = () => {
        indexData({ resource: `mobile/sureveys?type=surevey` }).then((res) => {
            const votes = res.data.data.items
            setVotes(votes);
        });
    };

    useEffect(() => {
        getVotes()
    }, []);


    return (
        <AnimatedPage>
            <section className='opinions page bg-white'>
                <Intro image={header} />
                <div className='page__content text-center mt-3'>
                    <Container>
                        <Grid container spacing={8} justifyContent='center'>
                            {votes.map((vote: any) => (

                                <Grid item xs={12} sm={6}>
                                    <Action
                                        page={`/survey/opinions/${vote.id}`}
                                        image={vote.image}
                                        title={vote.title}
                                        description={vote.small_description}
                                    />

                                </Grid>
                            ))}


                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            {
                                votes &&
                                <Typography fontWeight={'bolder'} fontSize={'40px'} mt={'50px'}>
                                    لا يوجد استطلاعات رأى الان
                                </Typography>
                            }
                        </Grid> */}


                    </Container>
                </div>

            </section>
        </AnimatedPage>
    )
}

export default Opinions