import { Button, Grid } from '@mui/material'
import { Container } from '@mui/system'
import Intro from '../../components/Intro'
import Item from '../../components/Blog'
import header from '../../assets/images/gallery/header.png';
import gallery1 from '../../assets/images/home/gallery1.png';
import AnimatedPage from '../../components/Animations/AnimatedPages';
import { useEffect, useState } from 'react';
import { indexData } from '../../plugins/api';
const Blogs = () => {

    const [blogs, setBlogs] = useState([]);
    const getBlogs = () => {
        indexData({ resource: `mobile/blogs` }).then((res) => {
            const blogs = res.data.data.items
            setBlogs(blogs);
        });
    };

    useEffect(() => {
        getBlogs()
    }, []);

    return (

        <AnimatedPage>
            <section className='gallery page'>
                <Intro image={header} />
                <div className='page__content'>
                    <Container>
                        <div className='gallery__cards'>
                            <div className='pt-3'>
                                <Grid container spacing={7} justifyContent={'space-evenly'}>
                                    {blogs.map((blog: any) => (
                                        <Grid item xs={12} md={4} sm={6}>
                                            <Item page={'blogs'} description={blog.description} image={blog.image} title={blog.title} id={blog.id} hint={''} />
                                        </Grid>
                                        
                                    ))}
                                        

                                </Grid>
                            </div>
                        </div>
                    </Container>
                </div>

            </section>
        </AnimatedPage>
    )
}

export default Blogs