import React, { useEffect, useState } from 'react'
import Intro from '../../components/Intro'
import header from '../../assets/images/events/header.png';
import address from '../../assets/images/events/address.png';
import calender from '../../assets/images/events/calender.png';
import clock from '../../assets/images/events/clock.png';
import notifyActive from '../../assets/images/events/notify-active.png';
import notify from '../../assets/images/events/notify.png';
import eventImage from '../../assets/images/events/event.png';
import Button from '../../components/Controls/Button';

import { Box, Container } from '@mui/system';
import {  ButtonBase, Card, CardContent, CardMedia, Grid, IconButton, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AnimatedPage from '../../components/Animations/AnimatedPages';
import DialogAdd from '../../components/Dialog/Add';
import AddEvent from '../../components/FormsWebsite/Events/AddEvent';
import { indexData } from '../../plugins/api';
import DialogFire from '../../components/Dialog/Fire';
import AddOffer from '../../components/FormsWebsite/Offers/Add';
import DialogSuccess from '../../components/Dialog/Success';


const Events = () => {


    const [confirmDialogSuccess, setConfirmDialogSuccess] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialogSuccess = () => {
        setConfirmDialogSuccess({
            isOpen: true,
            title: 'تم إرسال طلب الفعالية للإدارة بنجاح',
            subTitle: 'سوف يتم مراجعة بيانات ملاحظاتك مع الإدارة وإرسال الرد لك',
            onConfirm: () => { }
        })
    }


    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = () => {
        setConfirmDialog({
            isOpen: true,
            title: ' إضافة فعالية جديدة',
            subTitle: '',
            onConfirm: () => { }
        })
    }

    const [events, setEvents] = useState([]);
    const getEvents = () => {
        indexData({ resource: `mobile/events` }).then((res) => {
            const events = res.data.data.items
            setEvents(events);
        });
    };


    useEffect(() => {
        getEvents()
    }, []);


    const fetchData = () => {
        getEvents()
        setConfirmDialog({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })
        openDialogSuccess()
    };

    return (
        <AnimatedPage>
              <DialogSuccess confirmDialog={confirmDialogSuccess} setConfirmDialog={setConfirmDialogSuccess}></DialogSuccess>
            <section className='events page'>
                <Intro image={header} />
                <div className='events__content'>
                    <Container>
                        <Grid item xs container direction="row-reverse" spacing={2}>
                            <Button text={'اضافة فعالية'} onClick={openDialog}>
                                <Typography className='icon-btn' fontWeight={'bolder'} marginX={1} >+</Typography>
                            </Button>

                            <DialogFire btnName="اضافة الفعالية" confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}>
                                <AddEvent fetchData={fetchData}></AddEvent>
                            </DialogFire>
                        </Grid>

                        {events.map((item: any) => (
                            <div className='events__content__card'>
                                <Link to={`/events/${item.id}`}>
                                    <Paper className='paper'
                                        sx={{
                                            p: 1,
                                            margin: 'auto',
                                            flexGrow: 1,
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <ButtonBase sx={{ width: 128, height: 128 }}>
                                                    <img src={item.image} alt="event" className='events__content__card__media' />
                                                </ButtonBase>
                                            </Grid>
                                            <Grid item xs={12} sm container>
                                                <Grid item xs container direction="column" spacing={2}>
                                                    <Grid item xs>
                                                        <Typography className='text-head' gutterBottom variant="h5" component="h2">
                                                            {item.title}
                                                        </Typography>
                                                        <Typography className='text-name' variant="body2" gutterBottom>
                                                            {item.small_description}
                                                        </Typography>

                                                    </Grid>
                                                    <Grid item>

                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                        >
                                                            <Grid item className='item-events'>
                                                                <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                    <img src={calender} alt="calender" className='event-icon' />

                                                                    <span>
                                                                        {item.start}
                                                                    </span>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item className='item-events'>
                                                                <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                    <img src={clock} alt="clock" className='event-icon' />
                                                                    <span>
                                                                        {item.end}
                                                                    </span>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item className='item-events'>
                                                                <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                                                    <img src={address} alt="address" className='event-icon' />
                                                                    <span>
                                                                        {item.location_description}
                                                                    </span>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                {/* <Grid item className='notify'>
                                                    <Typography variant="subtitle1" component="div">
                                                        <img src={notify} alt="address" className='event-icon' />
                                                    </Typography>
                                                </Grid> */}
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Link>
                            </div>
                        ))}
                    </Container>
                </div>
            </section>
        </AnimatedPage>
    )
}

export default Events