import { Autocomplete, FormControl, FormLabel, Grid, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import dummyData from '../../../../plugins/data/data';
import UploadFile from '../../../Forms/UploadFile';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { storeOrUpdate } from '../../../../plugins/api';

import TextInputX from '../../../Forms/TextInputX';
import Button from '../../../Controls/Button';
import Notification from '../../../Notifications/Notification';
import DateInput from '../../../Forms/DateInput';


const AddFather = ({ fetchData, url, gender }) => {
    const defaultValues = null;
    const [value, setValue] = useState<Dayjs | null>(null);
    const formMethods = useForm<FormData>({
        defaultValues: defaultValues,
    });
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const { handleSubmit, setError } = formMethods;

    const handleDate = (value) => {
        let date = value.split('-')
        let dateFormat = date[2] + "/" + date[1] + "/" + date[0]
        return dateFormat;
    }

    const onFormSubmit = async (data: any) => {
        data.birthdate_g = handleDate(data.birthdate_g)

        let fd = new FormData();
        const keys = Object.keys(data);
        console.log(data)
        keys.forEach((key) => {
            if (key != 'image') {
                fd.append(key, data[key])
            }
            if (key == 'image') {
                fd.append(key, data[key][0])
            }
        });


        let actionRoute = { resource: `mobile/${url}`, param: null, data: fd, method: 'post' };
        if (defaultValues) {
            actionRoute = { resource: `mobile/${url}`, param: defaultValues.id, data: data, method: 'put' };
        }
        return storeOrUpdate(actionRoute).then(
            (response) => {
                fetchData()
           
            }
        ).catch((errors) => {
            setNotify({
                isOpen: true,
                message: errors.response.data.msg,
                type: 'error'
            })

        })

    };

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onFormSubmit)}>




                <TextInputX field={'first_name'} label={'الأسم الأول'}
                    rules={{
                        required: true
                    }} />


                <TextInputX field={'second_name'} label={'الأسم الثانى'}
                    rules={{
                        required: true
                    }} />



                <TextInputX field={'third_name'} label={'الأسم الثالث'}
                    rules={{
                        required: true,

                    }} />



                <TextInputX field={'national_id_no'} label={'رقم الهوية'}
                    defaultValue=""
                    rules={{

                    }} />


                <DateInput field={'birthdate_g'} label={'تاريخ الميلاد'}
                    defaultValue=""
                    rules={{

                    }} />

                <div hidden>
                    <TextInputX field={'date_type'} label={'تاريخ الميلاد'}
                        defaultValue="g"
                        rules={{

                        }} />


                    <TextInputX field={'gender'} label={''}
                        defaultValue={gender}
                        rules={{

                        }} />

                </div>


                <Grid container justifyContent={'center'}>
                    <Grid item>
                        <Button type='submit' className='btn-popup' variant='contained' autoFocus >
                            اضافة
                        </Button>
                    </Grid>
                </Grid>

                <Notification
                    notify={notify}
                    setNotify={setNotify}
                />
            </form>
        </FormProvider >

    )
}

export default AddFather