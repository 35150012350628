import { Autocomplete, Button, Card, CardActionArea, CardContent, FormControl, FormLabel, Grid, InputLabel, OutlinedInput, Paper, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Public from '../../../assets/images/icons/public.svg';
import all from '../../../../assets/images/icons/public.svg';
import only_me from '../../../../assets/images/icons/private.svg';
import male from '../../../../assets/images/icons/gender.svg';
import female from '../../../../assets/images/icons/gender.svg';
import header from '../../../assets/images/opinions/header.png';
import Intro from '../../../components/Intro';
import AnimatedPage from '../../../components/Animations/AnimatedPages';
import DialogFire from '../../../components/Dialog/Fire';
import { useState, useEffect } from "react"
import ProfilePrivacy from '../../../components/FormsWebsite/Profile/Privacy';
import dummyData from '../../../plugins/data/data';
import { genders, maritalStatus } from '../../../plugins/data/selectData';
import TextInputX from '../../../components/Forms/TextInputX';
import { indexData, storeOrUpdate } from '../../../plugins/api';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Dayjs } from 'dayjs';
import DateInput from '../../../components/Forms/DateInput';
import Notification from '../../../components/Notifications/Notification';
import UploadImage from '../../../components/Forms/UploadImage';
const EditProfile = () => {
    const getUser = JSON.parse(window.localStorage.getItem("user"));
    const [user, setUser] = useState(getUser);
    const [akey, setakey] = useState(null);
    const [val, setval] = useState(null);
    const [status, setStatus] = useState(null);


    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })

    const openDialog = (key, title) => {

        setakey(key)
        let val = getIconVal(key);
        setval(val)

        setConfirmDialog({
            isOpen: true,
            title: title,
            subTitle: '',
            onConfirm: () => { alert("hi") }
        })
    }

    const defaultValues = null;
    const [value, setValue] = useState<Dayjs | null>(null);
    const formMethods = useForm<FormData>({
        defaultValues: defaultValues,
    });
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const { handleSubmit, setError } = formMethods;

    const handleDate = (value) => {
        if (value) {
            let date = value.split('-')
            let dateFormat = date[2] + "/" + date[1] + "/" + date[0]
            return dateFormat;
        }
        else {
            return '2010-10-10'
        }
    }

    const getDate = (value) => {
        if (value) {
            let date = value.split('/')
            let dateFormat = date[2] + "-" + date[1] + "-" + date[0]
            return dateFormat;
        }

    }

    const onFormSubmit = async (data: any) => {

        if (data.birthdate_g) {
            data.birthdate_g = handleDate(data.birthdate_g)
        }
        else{
            data.birthdate_g = '10/10/2010'
        }

        let fd = new FormData();
        const keys = Object.keys(data);

        keys.forEach((key) => {
            if (key != 'marital_status' && key != 'image' && key != 'gender' && key != 'first_name' && key != 'second_name' && key != 'third_name')
                fd.append(key, data[key])

            if (key == 'image' && data[key].length != 0) {
                fd.append(key, data[key][0])
            }

        });


        fd.append('marital_status', status);

        fd.append("date_type", "g")
        fd.append("_method", "PUT")

        let actionRoute = { resource: `auth/editfamilymember/`, param: null, data: fd, method: 'post' };

        return storeOrUpdate(actionRoute).then(
            (response) => {
                getProfile();
                setNotify({
                    isOpen: true,
                    message: 'تم تحديث بياناتك بنجاح',
                    type: 'success'
                })


            }
        ).catch((errors) => {
            setNotify({
                isOpen: true,
                message: errors.response.data.msg,
                type: 'error'
            })
        })

    };

    const [maritialStatuses, setMaritialStatuses] = useState([]);
    const getMaritialStatuses = () => {
        indexData({ resource: `general/martial_status` }).then((res) => {
            const maritialStatuses = res.data.data
            setMaritialStatuses(maritialStatuses);

        });
    };

    useEffect(() => {
        getMaritialStatuses()
    }, []);


    const getProfile = async () => {
        await indexData({ resource: `profile` }).then((res) => {
            const user = res.data.data;
            setUser(user);
            window.localStorage.setItem("user", JSON.stringify(user));
            document.getElementById("img-user")['src'] = user.member.image

        });
    };


    const fetchData = () => {
        getProfile();
        setConfirmDialog({ isOpen: false, title: 'title', subTitle: 'subtile', onConfirm: () => { } })
    }


    const getIcon = (key) => {
        let getValue = user.policy.filter((element) => {
            return element.key == key
        })
        return `/icons/${getValue[0].value}.svg`;
    }

    const getIconVal = (key) => {
        let getValue = user.policy.filter((element) => {
            return element.key == key
        })
        return getValue[0].value
    }


    return (
        <AnimatedPage>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <DialogFire btnName="حفظ التعديلات" confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}>
                <ProfilePrivacy fetchData={fetchData} akey={akey} valueKey={val}  ></ProfilePrivacy>
            </DialogFire>
            {
                user.member &&

                <section className='profile'>
                    <Intro image={header} />
                    <div className='mt-2'>
                        <Container >
                            <FormProvider {...formMethods}>
                                <form onSubmit={handleSubmit(onFormSubmit)}>

                                    <Grid container xs spacing={3} justifyContent={'center'}>
                                        <Grid item xs={12} className='px-0' >
                                            <Paper className='profile__left'>

                                                <div className='text-center pt-1'>
                                                    <Typography fontWeight={'bolder'} gutterBottom variant="h5" component="div">
                                                        تعديل البيانات الشخصية
                                                    </Typography>

                                                </div>
                                                <div>
                                                    <UploadImage defaultIamge={user.member.image} rules={{}} field={'image'} label={''} />
                                                </div>


                                                <div className='mt-2'>
                                                    <Grid container alignItems={'center'} >
                                                        <Grid container md={6}>
                                                            <Grid container flexDirection={'column'}>

                                                                <Grid container alignItems={'center'}>
                                                                    <Grid item xs={10} flexGrow={1}>

                                                                        <TextInputX defaultValue={user.member.first_name} disabled field={'first_name'} label={'الأسم الأول'}
                                                                            rules={{

                                                                            }} />

                                                                    </Grid>

                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => openDialog('first_name', 'الأسم الأول')}
                                                                            className='profile__add mt-2'>

                                                                            <img
                                                                         width={'30px'}
                                                                         height={'30px'}
                                                                         src={getIcon('first_name')}
                                                                                alt="public"
                                                                            />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container md={6}>
                                                            <Grid container flexDirection={'column'}>

                                                                <Grid container alignItems={'center'}>
                                                                    <Grid item xs={10} flexGrow={1}>
                                                                        <TextInputX disabled defaultValue={user.member.second_name} field={'second_name'} label={' اسم الأب'}
                                                                            rules={{

                                                                            }} />
                                                                    </Grid>

                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => openDialog('father_name', 'الأسم الثانى')}
                                                                            className='profile__add mt-2'>

                                                                            <img
                                                                         width={'30px'}
                                                                         height={'30px'}
                                                                         src={getIcon('father_name')}
                                                                                alt="public"
                                                                            />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div >
                                                    <Grid container alignItems={'center'} >
                                                        <Grid container md={6}>
                                                            <Grid container flexDirection={'column'}>

                                                                <Grid container alignItems={'center'}>
                                                                    <Grid item xs={10} flexGrow={1}>
                                                                        <TextInputX disabled defaultValue={user.member.third_name} field={'third_name'} label={' اسم الجد'}
                                                                            rules={{

                                                                            }} />
                                                                    </Grid>

                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => openDialog('grandfather_name', ' اسم الجد')}
                                                                            className='profile__add mt-2'>

                                                                            <img
                                                                         width={'30px'}
                                                                         height={'30px'}
                                                                         src={getIcon('grandfather_name')}
                                                                                alt="public"
                                                                            />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container md={6}>
                                                            <Grid container flexDirection={'column'}>
                                                                <Grid item>

                                                                </Grid>
                                                                <Grid container alignItems={'center'}>
                                                                    <Grid item xs={10} flexGrow={1}>

                                                                        <TextInputX disabled defaultValue={user.member.gender == 'M' ? 'ذكر' : 'أنثى'} field={'gender'} label={' الجنس'}
                                                                            rules={{

                                                                            }} />

                                                                    </Grid>

                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => openDialog('gender', 'الجنس')}
                                                                            className='profile__add mt-2'>

                                                                            <img
                                                                         width={'30px'}
                                                                         height={'30px'}
                                                                         src={getIcon('gender')}
                                                                                alt="public"
                                                                            />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </div>

                                                <Grid container alignItems={'center'} >

                                                    <Grid container md={6}>
                                                        <Grid container flexDirection={'column'}>

                                                            <Grid container alignItems={'center'}>
                                                                <Grid item xs={10} flexGrow={1}>
                                                                    <TextInputX defaultValue={user.member.national_id_no} field={'national_id_no'} label={' رقم الهوية'}
                                                                        rules={{

                                                                        }} />
                                                                </Grid>

                                                                <Grid item>
                                                                    <Button
                                                                        onClick={() => openDialog('national_id', 'رقم الهوية')}
                                                                        className='profile__add mt-2'>

                                                                        <img
                                                                            src={getIcon('national_id')}
                                                                            alt="public"
                                                                        />
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container md={6}>
                                                        <Grid container flexDirection={'column'}>

                                                            <Grid container alignItems={'center'}>
                                                                <Grid item xs={10} flexGrow={1}>

                                                                    <TextInputX defaultValue={user.member.email} field={'email'} label={' البريد الألكترونى'}
                                                                        rules={{

                                                                        }} />


                                                                </Grid>

                                                                <Grid item>
                                                                    <Button
                                                                        onClick={() => openDialog('email', 'البريد الألكترونى')}
                                                                        className='profile__add mt-2'>

                                                                        <img
                                                                            src={getIcon('email')}
                                                                            alt="public"
                                                                        />
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <div >
                                                    <Grid container alignItems={'center'} >

                                                        <Grid container md={6}>
                                                            <Grid container flexDirection={'column'}>

                                                                <Grid container alignItems={'center'}>
                                                                    <Grid item xs={10} flexGrow={1}>
                                                                        <TextInputX defaultValue={user.member.phone} field={'phone'} label={' رقم الهاتف'}
                                                                            rules={{
                                                                                required: true
                                                                            }} />
                                                                    </Grid>

                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => openDialog('mobile', 'رقم الهاتف')}
                                                                            className='profile__add mt-2'>

                                                                            <img
                                                                         width={'30px'}
                                                                         height={'30px'}
                                                                         src={getIcon('mobile')}
                                                                                alt="public"
                                                                            />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container md={6}>
                                                            <Grid container flexDirection={'column'}>

                                                                <Grid container alignItems={'center'}>
                                                                    <Grid item xs={10} flexGrow={1}>
                                                                        <DateInput defaultValue={getDate(user.member.birthdate_g)} field={'birthdate_g'} label={' تاريخ الميلاد'}
                                                                            rules={{}} />


                                                                    </Grid>

                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => openDialog('birthday', 'تاريخ الميلاد')}
                                                                            className='profile__add mt-2'>

                                                                            <img
                                                                         width={'30px'}
                                                                         height={'30px'}
                                                                         src={getIcon('birthday')}
                                                                                alt="public"
                                                                            />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div >
                                                    <Grid container alignItems={'center'} >

                                                        <Grid container md={6}>
                                                            <Grid container flexDirection={'column'}>
                                                                <Grid item>

                                                                </Grid>
                                                                <Grid container alignItems={'center'}>
                                                                    <Grid item xs={10} flexGrow={1}>

                                                                        <FormControl fullWidth variant="outlined">

                                                                            <Autocomplete
                                                                                fullWidth
                                                                                disablePortal
                                                                                id="combo-box-demo"
                                                                                options={maritialStatuses}


                                                                                defaultValue={user.member.marital_status_object}

                                                                                getOptionLabel={(option: any) => {
                                                                                    setStatus(option.id);
                                                                                    return option.value

                                                                                }}


                                                                                renderInput={(params) => <TextInputX {...params} defaultValue={user.member.marital_status} field={'marital_status'} label={'الحالة الاجتماعية'}
                                                                                    rules={{}} />}
                                                                            />
                                                                        </FormControl>

                                                                    </Grid>

                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => openDialog('material_status', 'الحالة الاجتماعية')}
                                                                            className='profile__add mt-2'>

                                                                            <img
                                                                         width={'30px'}
                                                                         height={'30px'}
                                                                         src={getIcon('material_status')}
                                                                                alt="public"
                                                                            />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container md={6}>
                                                            <Grid container flexDirection={'column'}>

                                                                <Grid container alignItems={'center'}>
                                                                    <Grid item xs={10} flexGrow={1}>
                                                                        <TextInputX defaultValue={user.member.jobtitle} field={'jobtitle'} label={' الوظيفة'}
                                                                            rules={{}} />

                                                                    </Grid>

                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => openDialog('job', 'الوظيفة')}
                                                                            className='profile__add mt-2'>

                                                                            <img
                                                                         width={'30px'}
                                                                         height={'30px'}
                                                                         src={getIcon('job')}
                                                                                alt="public"
                                                                            />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div >
                                                    <Grid container alignItems={'center'} >

                                                        <Grid container md={6} >
                                                            <Grid container flexDirection={'column'}>

                                                                <Grid container alignItems={'center'}>
                                                                    <Grid item xs={10} flexGrow={1}>
                                                                        <TextInputX defaultValue={user.member.work_address} field={'work_address'} label={' عنوان العمل'}
                                                                            rules={{}} />
                                                                    </Grid>

                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => openDialog('work_address', 'عنوان العمل')}
                                                                            className='profile__add mt-2'>

                                                                            <img
                                                                         width={'30px'}
                                                                         height={'30px'}
                                                                         src={getIcon('work_address')}
                                                                                alt="public"
                                                                            />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container md={6}>
                                                            <Grid container flexDirection={'column'}>

                                                                <Grid container alignItems={'center'}>
                                                                    <Grid item xs={10} flexGrow={1}>
                                                                        <TextInputX defaultValue={user.member.address} field={'address'} label={'عنوان الأقامة'}
                                                                            rules={{}} />
                                                                    </Grid>

                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => openDialog('home_address', 'عنوان الأقامة')}
                                                                            className='profile__add mt-2'>

                                                                            <img
                                                                         width={'30px'}
                                                                         height={'30px'}
                                                                         src={getIcon('home_address')}
                                                                                alt="public"
                                                                            />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                                <div >
                                                    <Grid container alignItems={'center'} >
                                                        <Grid container md={6}>
                                                            <Grid container flexDirection={'column'}>

                                                                <Grid container alignItems={'center'}>
                                                                    <Grid item xs={10} flexGrow={1}>
                                                                        <TextInputX defaultValue={user.member.facebook} field={'facebook'} label={'رابط الفيسبوك'}
                                                                            rules={{}} />


                                                                    </Grid>

                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => openDialog('facebook', 'فيسبوك')}
                                                                            className='profile__add mt-2'>

                                                                            <img
                                                                         width={'30px'}
                                                                         height={'30px'}
                                                                         src={getIcon('facebook')}
                                                                                alt="public"
                                                                            />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container md={6}>
                                                            <Grid container flexDirection={'column'}>

                                                                <Grid container alignItems={'center'}>
                                                                    <Grid item xs={10} flexGrow={1}>
                                                                        <TextInputX defaultValue={user.member.instagram} field={'instagram'} label={' رابط الأنستجرام'}
                                                                            rules={{}} />
                                                                    </Grid>

                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => openDialog('instagram', 'انستجرام')}
                                                                            className='profile__add mt-2'>

                                                                            <img
                                                                         width={'30px'}
                                                                         height={'30px'}
                                                                         src={getIcon('instagram')}
                                                                                alt="public"
                                                                            />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div >
                                                    <Grid container alignItems={'center'} >
                                                        <Grid container xs={12}>
                                                            <Grid container flexDirection={'column'}>

                                                                <Grid container alignItems={'center'}>
                                                                    <Grid item xs={11} flexGrow={1}>
                                                                        <TextInputX defaultValue={user.member.about_info} field={'about_info'} label={'نبذة مختصرة عنك'}
                                                                            multiline
                                                                            rows={1}
                                                                            rules={{}} />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className='mt-2 text-center mb-2'>
                                                    <Button type='submit' className='profile__save' variant='contained'>
                                                        حفظ التعديلات
                                                    </Button>
                                                </div>
                                            </Paper>
                                        </Grid>

                                    </Grid>
                                </form>
                            </FormProvider>
                        </Container>
                    </div>
                </section >
            }
        </AnimatedPage>
    )
}



export default EditProfile