import { useFormContext } from "react-hook-form";
import TextField from '@mui/material/TextField';

import { FormControl, FormLabel } from "@mui/material";
import { useTranslation } from "react-i18next";


const TextInputX = ({ field, label, rules, showLabel=true, ...other }) => {
  
  const { register, formState: { errors } } = useFormContext();
  const { t } = useTranslation();

  return (
    <div>
      <FormLabel>
        {label}
      </FormLabel>
      <FormControl fullWidth variant="outlined" >
        <TextField
          {...other}

          fullWidth
          error={errors[field] ? true : false}
          {...register(field, rules)}
          label={ showLabel ?  label + '...' : ''}
   

        />
        <div>
          {errors[field] &&
            <span style={{ color: "red" }}>
              {t(errors[field].type)}
            </span>
          }
        </div>
      </FormControl>
    </div>

  );
};

export default TextInputX;
